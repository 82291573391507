import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.patient, { type, payload, requestedPatientId }) {
    switch (type) {
        case types.PATIENT_REQUEST:
            return {
                ...initialState.patient,
                isLoading: true,
                isStatus: 'request',
                request: payload,
                successResponse: {},
                errorResponse: {},
            };
        case types.PATIENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isStatus: 'success',
                successResponse: payload,
                request: {},
                patientId: requestedPatientId
            };
        case types.PATIENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                successResponse: {},
                errorResponse: payload,
                request: {}
            };
        default:
            return state;
    }
}