import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class PreRegPatientService {

    static create({ data }) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/patient/pre-registered/create'), data, requestOptions);
    }

    static uploadProfilePicture(data) {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.post(apiRoute('/v1/profilepicture'), data, requestOptions);
    }

    static deleteProfilePicture(id) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.delete(apiRoute("/v1/profile-pictures/" + id), requestOptions);
    }
}


export default PreRegPatientService;
