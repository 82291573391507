import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { startCase } from 'lodash';
import DataGrid from '../../components/DataGrid';
import { getDateFormat } from '../../utils/helpers'
import * as authAction from '../../actions/authAction';
import authService from '../../services/authService';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import SelectPage from '../../components/SelectPage';

class HistoricData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
        }
    }

    columns = () => {
        return [{
            dataField: 'attached_at',
            text: 'Attached Date',
            formatter: this.dateFormatter
        }, {
            dataField: 'file_name',
            text: 'File Name'
        }, {
            dataField: 'doctor',
            text: 'Doctor Name'
        }, {
            dataField: 'specialization',
            text: 'Specialization'
        }, {
            dataField: 'pdf_password',
            text: 'Password',
        }, {
            formatter: this.actionFormatter,
            text: 'Actions',
        }]
    }

    dateFormatter = (_cell, row) => {
        if (row) {
            return getDateFormat(row.attached_at)
        }
    }

    actionFormatter = (_cell, row) => {
        return <Link className="icon" onClick={() => this.actionClick(row)} ><i className="view-icon-icon"></i></Link>
    }

    actionClick = (data) => {
        this.props.showLoader();
        authService.downloadHistoricData(data.document_uuid).then(resp => {
            this.props.hideLoader();
            window.open(resp.data.url);
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data);
        });
    }

    componentDidMount() {
        if (this.props.patient.patientId !== this.state.patientId) {
            this.props.getPatientById(this.state.patientId);
        }
        this.props.getPatientHistoricData(this.state.patientId, { sortBy: { sortField: "updated_at", sortOrder: "desc" } })
    }

    handleTableChange = (type, { page, sizePerPage, sortField = "updated_at", sortOrder = "desc" }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getPatientHistoricData(this.state.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    render() {
        const { successResponse } = this.props.historic_data_list;
        const { patientId } = this.state;
        let patient_detail = this.props.patient.successResponse;
        return (<React.Fragment>
            <Helmet>
                <title>Historic Data</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header">
                        <Link to={"/patients/" + patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Back</Link>
                        <span className="patient-name">{patient_detail.full_name}</span>
                        <SelectPage history={this.props.history} patientId={patientId} />
                    </div>
                    <section>
                        <div className="container prescription-tabs">
                            <ul className="tabs">
                                <li className="tab-link active" data-tab="tab-1">Historic Data</li>
                            </ul>
                            <div id="tab-1" className="tab-content active">
                                <div className="">
                                    < DataGrid
                                        columns={this.columns()}
                                        data={successResponse.data}
                                        meta={successResponse.meta}
                                        handleTableChange={this.handleTableChange}
                                        noDataIndication="No Record Found" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </React.Fragment>);
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    historic_data_list: state.historic_data_list,
    patient: state.patient
});
const mapActionsToProps = {
    getPatientHistoricData: authAction.getPatientHistoricData,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getPatientById: authAction.getPatientById
};

export default connect(mapStateToProps, mapActionsToProps)(HistoricData);
