import moment from 'moment';
import { isEmpty, filter } from 'lodash'

export function config(key, value = null) {
    return process.env['REACT_APP_' + key];
}

export function apiRoute($url) {
    return config('API_URL') + $url;

}

export function getAuthorizationToken() {
    let authData = localStorage.getItem('redux-react-session/USER-SESSION');

    if (authData) {
        return JSON.parse(authData);
    }

    return false;
}

export function getAuthUser() {
    let userData = localStorage.getItem('redux-react-session/USER_DATA');

    if (userData) {
        return JSON.parse(userData);
    }

    return false;
}

export function getApiHeader(extraHeader = {}, checkAuth = true) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    if (checkAuth) {
        let tokens = getAuthorizationToken()

        if (tokens) {
            headers.Authorization = 'Bearer ' + tokens.access_token;
        }
    }

    return { ...headers, ...extraHeader };
}

export function createPaginateQueryString({ page = 1, sizePerPage = 10, sortBy = null, searchText = '', filter = null }) {
    let params = {
        page: page,
        limit: sizePerPage,
    };

    if (sortBy && sortBy.sortField) {
        let field = sortBy.sortField;
        let order = sortBy.sortOrder ? sortBy.sortOrder : 'asc';
        params.sort = field + ':' + order;
    }

    if (searchText) {
        params.search = searchText
    }

    if (filter) {
        Object.keys(filter).forEach(function (key) {
            params[key] = filter[key]
        });
    }

    return params;
}


export function getDateFormat(timestamp, format = 'DD-MM-YYYY') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function getTimeFormat(timestamp, format = 'HH:mm:ss') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function getDateTimeFormat(timestamp, format = 'DD-MM-YYYY HH:mm:ss') {
    if (!timestamp) {
        return "";
    }

    return moment(timestamp).format(format);
}

export function addingCommaInobject(obj) {
    if (obj) {
        return Object.keys(obj).map(function (k) { return obj[k] }).join(", ")
    }
}
export function getDurationType($type = '') {
    const durationType = {
        'day': '/7',
        'week': '/52',
        'month': '/12',
    };

    if ($type) {
        return durationType[$type];
    }

    return '';
}

export function getDosageType($type = '') {
    const dosageType = {
        'od': 'OD',
        'bd': 'BD/BID',
        'tid': 'TID',
        'qid': 'QID',
        'sos': 'SOS',
    };

    if ($type) {
        return dosageType[$type];
    }

    return '';
}
export function fullAddressStr(address) {

    if (!isEmpty(address)) {
        let addressArr = []

        addressArr.push(address.address_line_1);
        addressArr.push(address.address_line_2 ? address.address_line_2 : '');
        addressArr.push(address.address_line_3 ? address.address_line_3 : '');
        addressArr.push(address.parish ? address.parish : '');
        addressArr.push(address.city ? address.city : '');
        addressArr.push(address.pincode ? address.pincode : '');

        addressArr = filter(addressArr);

        return addressArr.join(', ');
    }

    return '';
}

export function personNameRegex() {
    return '^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$'
}
