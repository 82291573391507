import React from 'react';
import { Helmet } from 'react-helmet';

class ContactUS extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>Faqs</title>
				</Helmet>

				<section className="middle-wrapper contact-wrapper">
					<div className="container">
						<div className="title-wrapper">
							<h4 className="bl-heading">Frequently Asked Questions</h4>
						</div>
						<div className="faqs-detail box">
							<div class="accordian-wrapper">
								<p>About Daisy health</p>
								<div class="accord-content">
									<ul>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>What is Daisy Health?</span></h5>
											<p className="q-a"><span>A.</span> <span>Daisy Health is an ecosystem that keeps patients, physicians and medical service providers
										data synced and readily accessible.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>Am I entitled to software updates/upgrades?</span></h5>
											<p className="q-a"><span>A.</span> <span>As soon as upgrades are available, patients will be able to download the same.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>Can I access Daisy Health offline?</span></h5>
											<p className="q-a"><span>A.</span> <span>No, the data is not available offline.</span></p>
										</li>
									</ul>
								</div>
							</div>
							<div class="accordian-wrapper">
								<p>Getting Started</p>
								<div class="accord-content">
									<ul>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>What information do I need to sign up?</span></h5>
											<p className="q-a"><span>A.</span> <span>Basic personal information is needed for sign up, any information requirements for the
                                             profile can be added in due course.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>How to add a patient?</span></h5>
											<p className="q-a"><span>A.</span> <span>Patients are added using from a Doctors Office or Medical Centre only.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>How to add family members to profile?</span></h5>
											<p className="q-a"><span>A.</span> <span>Family is added using Member Code found on an existing patients’ card, an OTP is sent to
                                             the registered mobile number of the existing patient for a two-step verification process.</span></p>
										</li>
									</ul>
								</div>
							</div>
							<div class="accordian-wrapper">
								<p>Data and Security</p>
								<div class="accord-content">
									<ul>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>How secure is my data?</span></h5>
											<p className="q-a"><span>A.</span> <span>Pharma Online Limited takes security very seriously and ensures your data is secure and
                                             available to you. With this in mind, we have ensured that our software is HIPPA Compliant.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>What happens to my data if I cancel?</span></h5>
											<p className="q-a"><span>A.</span> <span>The data is immutable, if a patient chooses to discontinue use of the card, the information,
                                            although inaccessible, will remain on the system.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>Who can view my data?</span></h5>
											<p className="q-a"><span>A.</span> <span>The primary physician has access to the data. In instances of a referral, the referrer can
											choose to share partial or complete data. Medical service providers will have access to data
                                            only pertaining to their speciality.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>Can I control who views my data?</span></h5>
											<p className="q-a"><span>A.</span> <span>Yes, there are provisions in place for patients to revoke or allow access to the data.</span></p>
										</li>
										<li>
											<h5 className="q-a"><span>Q.</span> <span>Can I download a copy of my data?</span></h5>
											<p className="q-a"><span>A.</span> <span>Data is accessible through the application at all time and from anywhere in the world, however,
                                            it cannot be downloaded.</span></p>
										</li>
									</ul>
								</div>
							</div>
							<div class="accordian-wrapper">
								<p>Account Settings and Troubleshooting</p>
								<div class="accord-content">
									<p>Connection Problems – If you cannot connect to Daisy Health, this is usually caused by a problem
                                     with your internet connection or your phone’s settings. It might not be necessary to delete and
                                     reinstall the application.</p>
                                    <p>Most Connection issues can be resolved by doing the following:</p>
									<ul className="inner-list">
										<li>Update the app to the latest version</li>
										<li>Make sure your WiFi stays on during sleep mode</li>
										<li>Upgrade your phone’s operating system to the latest version</li>
										<li>Restart your phone by turning it off and back on.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

			</React.Fragment>);
	}
}

export default ContactUS;