import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as authAction from '../../actions/authAction';
import { isEmpty, capitalize, groupBy, map, uniqBy, } from 'lodash';
import { getDateFormat } from '../../utils/helpers';
import DoctorInfo from '../../components/DoctorInfo';
import ViewVital from '../../components/Prescription/ViewVital';
import ViewExamination from '../../components/Prescription/ViewExamination';
import ViewPersonalHistory from '../../components/Prescription/History/PersonalHistory/ViewPersonalHistory';
import ViewFamilyHistory from '../../components/Prescription/History/FamilyHistory/ViewFamilyHistory';
import ViewSurgicalHistory from '../../components/Prescription/History/SurgicalHistory/ViewSurgicalHistory';
import ViewGynaecologyHistory from '../../components/Prescription/History/Gynaecology/ViewGynaecologyHistory';
import ShowMedicineBlock from '../../components/Prescription/ShowMedicineBlock';
import * as ShowCptIcd from '../../components/Prescription/ShowCptIcd';
import ShowPathologyTest from '../../components/Prescription/ShowPathologyTest';
import ShowRadiologyTest from '../../components/Prescription/ShowRadiologyTest';
import AuthService from '../../services/authService';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';

class PrescriptionDetail extends React.Component {
    constructor(props) {
        super(props);
        const patientId = this.props.match.params.patientId;
        const prescriptionId = this.props.match.params.prescriptionId;
        this.props.getPrescriptionDetail(patientId, prescriptionId);
        this.state = {
            patientId,
            prescriptionId,
        }
    }

    renderBodyParts = () => {
        const { successResponse } = this.props.prescriptiondetail;
        const uniqueArray = uniqBy(successResponse.body_charts, 'image_name');
        return map(uniqueArray, (obj, idx) => obj.image_name + (idx !== uniqueArray.length - 1 ? ", " : ' '));
    }
    downloadBodyChartPdf = () => {
        this.props.showLoader();
        AuthService.downloadBodyChart(this.state.prescriptionId).then(resp => {
            window.open(resp.data.url, '_blank')
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    downloadBodyChartImagePdf = () => {
        this.props.showLoader();
        AuthService.downloadBodyChartImage(this.state.prescriptionId).then(resp => {
            if(resp.data.url)
            window.open(resp.data.url, '_blank')
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    render() {
        const { successResponse } = this.props.prescriptiondetail;
        let modifiedIcdCpts = []
        if (successResponse && successResponse.icd_cpts) {
            const prescription_icd_data = groupBy(successResponse.icd_cpts, 'icd_code');
            Object.keys(prescription_icd_data).forEach((key) => {
                prescription_icd_data[key].forEach((icd, _index) => (
                    modifiedIcdCpts.push({
                        selectedIcd: {
                            icd_code: icd.icd_code,
                            full_description: icd.icd_description
                        },
                        icdCrosswalks: [{
                            cpt_code: icd.cpt_code,
                            full_description: icd.cpt_description
                        }]
                    })
                ))
            });
        }
        return (<React.Fragment>
            <Helmet>
                <title>Prescription Info</title>
            </Helmet>

            {!isEmpty(successResponse) && <section className="middle-wrapper v-center">
                <div className="container">
                    <div className="list-header">
                        <Link to={"/patients/" + this.state.patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Prescriptions </Link>
                        <span className="prescription-details">Prescription Id: <i>{successResponse.prescription_id}</i></span>
                        <span className="date">Prescription Date: <i>{getDateFormat(successResponse.prescription_date)}</i></span>
                    </div>
                    <section>
                        <div className="prescription-list">
                            {/* Patient Info */}
                            {successResponse.patient && <div className="content-wrapper">
                                <h6>Patient Info</h6>
                                <ul className="details-listing">
                                    <li>Name: <span>{successResponse.patient.full_name}</span></li>
                                    <li>Age: <span>{successResponse.patient.age.year + " year " + successResponse.patient.age.month + ' month'}</span></li>
                                    <li>Gender: <span>{capitalize(successResponse.patient.gender)}</span></li>
                                </ul>
                            </div>}
                            {/* Doctor and doctor location info */}
                            {successResponse.doctor && <DoctorInfo
                                doctor={successResponse.doctor}
                                doctor_location_info={successResponse.doctor_location}
                            />}
                            {/* Vital info */}
                            {successResponse.vital && <ViewVital
                                vital={successResponse.vital}
                                patient={successResponse.patient}
                            />}
                            {(!isEmpty(successResponse.personal_history) || !isEmpty(successResponse.family_history) || !isEmpty(successResponse.surgical_history) || !isEmpty(successResponse.obstetrics_gynaecology) || !isEmpty(successResponse.current_medicine)) &&
                                <div className="content-wrapper">
                                    <h6>History</h6>
                                    {successResponse.personal_history && <ViewPersonalHistory
                                        personal_history={successResponse.personal_history}
                                    />}
                                    {successResponse.family_history && <ViewFamilyHistory
                                        family_history={successResponse.family_history}
                                    />}
                                    {!isEmpty(successResponse.surgical_history) && <div className="content-wrapper">
                                        <h6>Surgical History</h6>
                                        {successResponse.surgical_history.map((item, key) => (
                                            <ViewSurgicalHistory item={item} key={key} />
                                        ))}
                                    </div>}
                                    {successResponse.obstetrics_gynaecology && successResponse.patient.gender !== 'male' && <ViewGynaecologyHistory
                                        obstetrics_gynaecology={successResponse.obstetrics_gynaecology}
                                    />}
                                    {!isEmpty(successResponse.current_medicine) &&
                                        <div className="content-wrapper">
                                            <h6>Current Medicine</h6>
                                            <ShowMedicineBlock medicines={successResponse.current_medicine} type="current_medicine" />
                                        </div>}
                                </div>}
                            {successResponse.symptoms && <div className="content-wrapper">
                                <h6>Presenting Condition</h6>
                                <p>{successResponse.symptoms}</p>
                            </div>}
                            {(successResponse.examination_note || !isEmpty(successResponse.review_of_system) || !isEmpty(successResponse.examination)) && <div className="content-wrapper">
                                <h6>Examination</h6>
                                <div className="content-wrapper">
                                    <h6>Examination Note</h6>
                                    <p>{successResponse.examination_note ? successResponse.examination_note : '--'}</p>
                                </div>
                                <ViewExamination
                                    examination={successResponse.examination}
                                    review_of_system={successResponse.review_of_system}
                                />
                            </div>}
                            {(!isEmpty(modifiedIcdCpts) || successResponse.assessment_remark || successResponse.body_charts || successResponse.body_chart_uploads) && <div className="content-wrapper">
                                <h6>Assessment</h6>
                                {!isEmpty(modifiedIcdCpts) &&
                                    <div className="content-wrapper">
                                        <h6>ICD CPT</h6>
                                        {modifiedIcdCpts.map((icd, index) => (<ShowCptIcd.ShowIcd icd={icd} idx={index} key={index} />))}
                                    </div>}
                                {successResponse.body_charts && <div className="content-wrapper">
                                    <h6>Body Chart</h6>
                                    <p>{this.renderBodyParts()}<a href="javascript:void(0)" onClick={this.downloadBodyChartPdf}>View</a></p>
                                </div>}
                                {successResponse.body_chart_uploads && <div className="content-wrapper">
                                    <h6>Body Chart Uploaded Images</h6>
                                    <p><a href="javascript:void(0)" onClick={this.downloadBodyChartImagePdf}>View</a></p>
                                </div>}
                                <div className="content-wrapper">
                                    <h6>Assessment Remark</h6>
                                    <p>{successResponse.assessment_remark ? successResponse.assessment_remark : '--'}</p>
                                </div>
                            </div>}
                            {(successResponse.prescription_medicine_items || successResponse.prescription_pathology_test_items || successResponse.prescription_radiology_test_items) && <div className="content-wrapper">
                                <h6>Prescription and Recommendation</h6>
                                {/* Medication info */}
                                {successResponse.prescription_medicine_items && <div className="content-wrapper">
                                    <h6>Prescribed Medication</h6>
                                    <ShowMedicineBlock
                                        medicines={successResponse.prescription_medicine_items}
                                    />
                                </div>}
                                {/* pathology info */}
                                {successResponse.prescription_pathology_test_items &&
                                    <ShowPathologyTest
                                        pathology_tests={successResponse.prescription_pathology_test_items}
                                    />}
                                {/* radiology info */}
                                {successResponse.prescription_radiology_test_items &&
                                    <ShowRadiologyTest
                                        radiology_tests={successResponse.prescription_radiology_test_items}
                                    />}
                            </div>}
                            {successResponse.prescription_remarks && <div className="content-wrapper">
                                <h6>Remark</h6>
                                <p>{successResponse.prescription_remarks}</p>
                            </div>}
                            {!isEmpty(successResponse.prescription_refer_to) && <div className="content-wrapper">
                                <h6>Refer a Doctor</h6>
                                <p>{successResponse.prescription_refer_to.full_name} ({successResponse.prescription_refer_to.specialization})</p>
                            </div>}
                        </div>
                    </section>
                </div>
            </section>}
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    prescriptiondetail: state.prescriptiondetail,
});

const mapActionsToProps = ({
    getPrescriptionDetail: authAction.getPrescriptionDetail,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
})

export default connect(mapStateToProps, mapActionsToProps)(PrescriptionDetail);
