import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function appointment(state = initialState.list, { type, payload }) {
    switch (type) {
        case types.PATIENT_APPOINTMENT_LIST_REQUEST:
            return {
                ...initialState.list,
                isLoading: true,
                isStatus: 'request',
                request: payload,
                successResponse: {},
                errorResponse: {}
            };
        case types.PATIENT_APPOINTMENT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isStatus: 'success',
                successResponse: payload,
                request: {}
            };
        case types.PATIENT_APPOINTMENT_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                successResponse: {},
                errorResponse: payload,
                request: {}
            }
        default: return state
    }
}