import * as types from './actionTypes';
import PreRegPatientService from '../services/preRegPatientService';
import Notification from '../utils/notification';

export function reset() {
    return dispatch => {
        dispatch({ type: types.PATIENT_LIST_RESET });
        dispatch({ type: types.RESET_PATIENT });
    }
}


export function add(data, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.ADD_PRE_REG_PATIENT, ...data });

        PreRegPatientService.create({ data })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.ADD_PRE_REG_PATIENT, ...data });
                Notification.show('success', { message: 'Form Submitted Successfully.' })
                setTimeout(() => {
                    Notification.clearAll();
                    history.push('/thank-you/' + data.id);
                }, 2000);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    Notification.show('error', error.response.data);
                }
            });
    }
}