import React from 'react';
import { isEmpty } from 'lodash';

class ViewExamination extends React.Component {
    render() {
        let { review_of_system, examination } = this.props;
        return <React.Fragment>
            {!isEmpty(examination) && <div className="content-wrapper">
                <h6>Overall Examination</h6>
                {examination.map((item, index) => (
                    <React.Fragment key={index}>
                        <ul className="prescription-filed-details">
                            <li>{item.category}<span><span>{item.problems.join(', ')}</span></span></li>
                        
                        {item.comments && <li>
                                Comment:
                                <span>{item.comments}</span>
                            </li>}
                        </ul>
                    </React.Fragment>
                ))}
            </div>}
            {!isEmpty(review_of_system) && <div className="content-wrapper">
                <h6>Review of System</h6>
                {review_of_system.map((item, index) => (
                    <React.Fragment key={index}>
                        <ul className="prescription-filed-details" key={index}>
                            <li>{item.category}<span><span>{item.problems.join(', ')}</span></span></li>
                        {item.comments && <li>
                                Comment:
                                <span>{item.comments}</span>
                            </li>}
                        </ul>
                    </React.Fragment>
                ))}
            </div>}
        </React.Fragment>
    }
}
export default ViewExamination;