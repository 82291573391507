import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isEmpty, find } from 'lodash';
import { Form, Row, Col, Button, Pagination } from 'react-bootstrap';
import * as SlotComponents from '../../components/Slot/SlotComponents';
import * as authAction from '../../actions/authAction';
import AuthService from '../../services/authService';
import * as loaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';

class ScheduleAppointmentPage extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        let { state } = this.props.location, appointment = null;
        let { params: { type } } = this.props.match;
        if (isEmpty(state) && type === 'reschedule') {
            this.props.history.push('/appointments');
        }
        if (!isEmpty(state) && type === 'reschedule') {
            appointment = state.appointment;
        }

        this.state = {
            patient_id: appointment ? appointment.patient_id : '',
            specialization: appointment ? appointment.specialization : '',
            specializations: '',
            doctors: '',
            doctor_profile_id: appointment ? appointment.doctor_id : '',
            locations: '',
            selected_location: appointment ? { name: appointment.location_name, ...appointment.address } : '',
            to: '',
            from: '',
            dates: '',
            selected_doctor: appointment ? { full_name: appointment.doctor_name } : '',
            user_location_id: appointment ? appointment.location_id : '',
            current_dates_pointer: 0,
            slot_id: '',
            selected_date_slot: '',
            appointment_id: appointment ? appointment.id : ''
        }
    }
    componentDidMount() {
        const { patients } = this.props;
        if (isEmpty(patients.successResponse))
            this.props.getPatient();

        this.props.loaderShow();
        AuthService.getMasters('doctor-specialization').then(resp => {
            this.setState({
                specializations: resp.data.data
            })
            this.props.loaderHide();
        }).catch(_err => {
            this.props.loaderHide();
            Notification.show('error', { 'message': 'Something went wrong!' })
        })
    }
    _onSpecializationChange = (query) => {
        if (query) {
            const filter = {
                role_slug: 'doctor',
                specialization: query
            };
            this.props.loaderShow();
            AuthService.searchDoctor({ filter }).then(resp => {
                this.setState({
                    specialization: query,
                    doctors: resp.data.data,
                    doctor_profile_id: '',
                    selected_date_slot: '',
                    selected_date: '',
                    dates: '',
                    locations: '',
                    selected_location: '',
                })
                this.props.loaderHide();
            }).catch(err => {
                this.props.loaderHide();
                Notification.show('error', { 'message': 'Something went wrong!' })
            })
        } else if (!query) {
            this.setState({
                specialization: '',
                doctors: '',
                doctor_profile_id: '',
                selected_date_slot: '',
                selected_date: '',
                dates: '',
                locations: '',
                selected_location: '',
            })
        }
    }
    _onChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value })
    }
    _onLocationChange = (evt) => {
        let location_id = evt.target.value
        if (location_id) {
            let selected_location = null;
            this.state.locations.find(location => {
                if (location.location_id === location_id) {
                    selected_location = location;
                    return;
                }
            });
            this.setState({
                selected_location,
                user_location_id: location_id,
                dates: '',
                current_dates_pointer: 0,
                slot_id: '',
                selected_date_slot: '',
                selected_date: ''
            })
        } else if (!location_id) {
            this.setState({
                selected_location: '',
                user_location_id: '',
                dates: '',
                current_dates_pointer: 0,
                slot_id: '',
                selected_date_slot: '',
                selected_date: ''
            })
        }
    }
    _onDoctorChange = (evt) => {
        evt.persist();
        let doctor_profile_id = evt.target.value;
        if (doctor_profile_id) {
            let selected_doctor = null;

            this.state.doctors.find((doctor) => {
                if (doctor_profile_id === doctor.user_profile_id) {
                    selected_doctor = doctor;
                    return;
                }
            })
            this.props.loaderShow();
            AuthService.doctorLocation(doctor_profile_id).then(resp => {
                this.setState({
                    doctor_profile_id: doctor_profile_id,
                    locations: resp.data.data,
                    selected_doctor,
                    dates: '',
                    current_dates_pointer: 0,
                    slot_id: '',
                    selected_date_slot: ''
                })
                this.props.loaderHide();
            }).catch(_err => {
                this.props.loaderHide();
                Notification.show('error', { 'message': 'Something went wrong!' })
            })
        } else if (!doctor_profile_id) {
            this.setState({
                doctor_profile_id: '',
                locations: '',
                selected_location: '',
                selected_doctor: '',
                dates: '',
                current_dates_pointer: 0,
                slot_id: '',
                selected_date_slot: '', selected_date: ''
            })
        }
    }
    onReset = () => {
        this.setState({
            patient_id: '',
            specialization: '',
            doctors: '',
            doctor_profile_id: '',
            locations: '',
            selected_location: '',
            to: '',
            from: '',
            dates: '',
            selected_doctor: '',
            user_location_id: '',
            current_dates_pointer: 0,
            slot_id: '',
            selected_date_slot: ''
        })
    }
    handleSearch = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.getSlotCount()
        }

    }
    getSlotCount = () => {
        this.props.loaderShow()
        let params = {
            location_id: this.state.user_location_id,
            doctor_profile_id: this.state.doctor_profile_id,
            to: this.state.to,
            from: this.state.from
        }
        AuthService.slotCount(params).then(resp => {
            this.props.loaderHide();
            let { data } = resp.data;
            if (data.length === 0) {
                Notification.show('error', { message: 'No slots available.' });
                return false;
            } else {
                let slot_days = [], startDate = moment(this.state.from), endDate = moment(this.state.to), now = startDate.clone();
                while (now.isSameOrBefore(endDate)) {
                    slot_days.push(now.format('YYYY-MM-DD'));
                    now.add(1, 'days');
                }
                let dates = [];
                slot_days.map(date => {
                    let found = find(data, (item) => {
                        if (moment(date).isSame(item.date, 'day')) return date
                    });
                    dates.push(this.getDateArray(date, found))
                })
                this.setState({ dates })
            }
        }).catch(_error => {
            this.props.loaderHide();
            Notification.show('error', { message: 'Something went wrong.' })
        })
    }
    getDateArray = (date, found) => {
        if (moment().isSame(date, 'day')) {
            return {
                label: 'Today',
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        } else if (moment().add(1, 'days').isSame(date, 'day')) {
            return {
                label: 'Tomorrow',
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        } else {
            return {
                label: moment(date, 'YYYY-MM-DD').format('dddd'),
                date_label: moment(date, 'YYYY-MM-DD').format('DD MMMM'),
                date: date,
                count: found ? found.count : 0,
            }
        }
    }
    handleDatePagination = (type) => {
        let { current_dates_pointer, dates } = this.state;
        if (type === 'next') {
            current_dates_pointer = ((dates.length - 1) > (current_dates_pointer + 6)) ? current_dates_pointer + 6 : current_dates_pointer;
        } else if (type === 'prev') {
            current_dates_pointer = (0 <= (current_dates_pointer - 6)) ? current_dates_pointer - 6 : current_dates_pointer
        }
        this.setState({ current_dates_pointer })
    }
    getCountSpan = (date) => {
        return date.count ? <span className="schedule-appmnt-slot">{(date.count + " Slot Available")}</span> : <span className="schedule-appmnt-slot no-available">(No Slot Available)</span>
    }
    handleSelectedDate = (date) => {
        let { user_location_id, doctor_profile_id, selected_date_slot } = this.state;
        if (date.count > 0) {
            this.props.loaderShow();
            const queryParams = {
                doctor_profile_id: doctor_profile_id,
                user_location_id: user_location_id,
                from: date.date,
                to: date.date,
            }
            AuthService.getSlot({ filter: queryParams }).then(resp => {
                this.props.loaderHide()
                if (resp.data.data.length > 0) {
                    let slots = resp.data.data[0].slots;
                    selected_date_slot = {
                        'Morning': [], 'Afternoon': [], 'Evening': []
                    }
                    slots.map(slot => {
                        let time = moment(slot.start_time, ["HH:mm:ss"])

                        if (time.format("HH") < 12) {
                            selected_date_slot['Morning'] = [...selected_date_slot['Morning'], slot]
                        } else if (time.format("HH") >= 12 && time.format("HH") < 17) {
                            selected_date_slot['Afternoon'] = [...selected_date_slot['Afternoon'], slot]
                        } else if (time.format("HH") >= 17) {
                            selected_date_slot['Evening'] = [...selected_date_slot['Evening'], slot]
                        }
                    })
                }
                this.setState({ selected_date_slot, selected_date: date.date });
            }).catch(err => {
                this.props.loaderHide();
                Notification.show('error', err.response)
            });
        } else {
            this.setState({ selected_date_slot: {}, selected_date: date.date });
        }
    }
    sliceDateArray = () => {
        let { current_dates_pointer, dates } = this.state;
        return dates.slice(current_dates_pointer, current_dates_pointer + 6)
    }
    handleSlotSelection = (item) => {
        this.setState({ slot_id: item.id })
    }
    handleSubmit = (evt) => {
        evt.preventDefault();
        if (!this.state.slot_id) {
            Notification.show('error', { message: 'Kindly select a slot.' });
            return false
        }
        let { params: { type } } = this.props.match
        const data = {
            doctor_id: this.state.doctor_profile_id,
            user_location_id: this.state.user_location_id,
            from: this.state.from,
            to: this.state.to,
            slot_id: this.state.slot_id,
            service_provider_id: this.state.selected_doctor.service_provider_id
        }
        if (type === 'reschedule')
            this._onRescheduleAppointment(data)

        if (type === "schedule")
            this._onScheduleAppointment(data)
    }
    _onScheduleAppointment = (data) => {
        this.props.loaderShow();
        AuthService.bookAppointment(data, this.state.patient_id).then(resp => {
            this.props.loaderHide()
            Notification.show('success', resp.data);
            this.props.history.push('/appointments')
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        });
    }
    _onRescheduleAppointment = (data) => {
        this.props.loaderShow();
        AuthService.rescheduleAppointment(this.state.patient_id, this.state.appointment_id, data).then(resp => {
            this.props.loaderHide()
            Notification.show('success', resp.data);
            this.props.history.push('/appointments')
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        });
    }
    render() {
        let { params: { type } } = this.props.match;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Appointment</title>
                </Helmet>
                <section className="middle-wrapper">
                    <div className="container">
                        <div className="list-header pb-3">
                            <Link to="/appointments" className="white-bg"><span>&#8249;</span> Back</Link>
                            <span className=""><span className="font-color-blue font-medium">Schedule Appointment</span></span>
                        </div>
                        <Form className="font-13">
                            <div className="content-wrapper">
                                <div className="row">
                                    <SlotComponents.SelectPatient
                                        selected_patient={this.state.patient_id}
                                        patients={this.props.patients.successResponse}
                                        handleSelectPatient={this._onChange}
                                        appointment_type={type} />
                                </div>
                                {this.validator.message('patient', this.state.patient_id, 'required')}
                            </div>
                            <div className="content-wrapper">
                                <div className="row">
                                    <SlotComponents.SelectSpecialization
                                        specializations={this.state.specializations}
                                        selected_specialization={this.state.specialization}
                                        handleSpecializationChange={this._onSpecializationChange}
                                        appointment_type={type} />

                                    <SlotComponents.SelectDoctor
                                        selected_doctor={this.state.doctor_profile_id}
                                        doctor_name={this.state.selected_doctor.full_name}
                                        doctors={this.state.doctors}
                                        handleDoctorChange={this._onDoctorChange}
                                        appointment_type={type} />

                                    <SlotComponents.SelectLocation
                                        selected_location={this.state.selected_location}
                                        location_name={this.state.selected_location.location_name}
                                        locations={this.state.locations}
                                        handleLocationChange={this._onLocationChange}
                                        appointment_type={type} />

                                    <SlotComponents.SelectDuration
                                        from={this.state.from}
                                        to={this.state.to}
                                        handleDuration={this._onChange} />

                                </div>
                                <div className="row">
                                    {this.validator.message('specialization', this.state.specialization, 'required')}
                                    {this.validator.message('doctor', this.state.doctor_profile_id, 'required')}
                                    {this.validator.message('location', this.state.selected_location, 'required')}
                                    {this.validator.message('from', this.state.from, 'required')}
                                    {this.validator.message('to', this.state.to, 'required')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-24 d-flex justify-content-md-end">
                                    {type !== 'reschedule' && <button type="button" className="mt-4 btn no-bg text-uppercase w-auto p-2 pl-4 pr-4 min-w-inherit font-13 mr-3" onClick={this.onReset}>Reset</button>}
                                    <button type="button" className="mt-4 btn text-uppercase w-auto p-2 pl-4 pr-4 min-w-inherit font-13" onClick={this.handleSearch}>Search Slot</button>
                                </div>
                            </div>
                        </Form>
                        {this.state.dates && <div className="appointment_table row mt-5">
                            <div className="col-24">
                                <div className="list-header pb-3 mt-5">
                                    <span className="">
                                        <span className="font-color-blue font-medium">{this.state.selected_doctor.full_name}</span>
                                        <span className="font-color-grey float-right">Location: <span className="font-medium font-color-blue">{this.state.selected_location.location_name}</span></span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-24">
                                <div className="content-wrapper">
                                    <Row className="pagination-mfixes">
                                        <Col md={24} className="p-0">
                                            <Pagination className="custom_pagination">
                                                <Pagination.Prev disabled={this.state.dates.length <= 6 ? "disabled" : ''} onClick={() => this.handleDatePagination('prev')} />
                                                {this.sliceDateArray().map(date => (
                                                    <Pagination.Item onClick={() => this.handleSelectedDate(date)} active={this.state.selected_date === date.date ? 'active' : ''}>
                                                        {
                                                            <span>{date.label}<span className="schedule-appmnt-date">{date.date_label}</span>{this.getCountSpan(date)}</span>

                                                        }
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next disabled={this.state.dates.length <= 6 ? "disabled" : ''} onClick={() => this.handleDatePagination('next')} />
                                            </Pagination>
                                        </Col>
                                    </Row>

                                    <Row className="appmnt-slots font-14">
                                        {this.state.selected_date && isEmpty(this.state.selected_date_slot) && <Col md={24}>
                                            <div className="appmnt-slots-data text-center">
                                                <div className="text-danger">(No Slot Available)</div>
                                            </div>
                                        </Col>}
                                        {!isEmpty(this.state.selected_date_slot) && this.state.selected_date && Object.keys(this.state.selected_date_slot).map((key) => (
                                            this.state.selected_date_slot[key].length > 0 && <SlotComponents.Slot
                                                slot={this.state.selected_date_slot[key]}
                                                key={key}
                                                day={key}
                                                slot_id={this.state.slot_id}
                                                handleSlotSelection={this.handleSlotSelection}
                                                selected_date={this.state.selected_date}
                                            />
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <div className="appmnt-slots-foo d-flex">
                                                <div className="slots-data-sign align-self-center">
                                                    {/* <span className="slots-data-sign-booked">Booked</span> */}
                                                    <span className="slots-data-sign-Blocked">Booked</span>
                                                    <span className="slots-data-sign-Selected">Selected</span>
                                                </div>
                                                <Button variant="mt-4 mr-3 btn text-uppercase w-auto p-2 pl-4 pr-4 min-w-inherit font-13" onClick={this.handleSubmit}>
                                                    Schedule
                                                </Button>
                                                <Button variant="mt-4 btn no-bg text-uppercase w-auto p-2 pl-4 pr-4 min-w-inherit font-13 mr-3" onClick={() => {
                                                    this.props.history.push('/appointments')
                                                }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section>

            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    patients: state.patients
});

const mapActionsToProps = ({
    getPatient: authAction.getPatient,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
})
export default connect(mapStateToProps, mapActionsToProps)(ScheduleAppointmentPage);