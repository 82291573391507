import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.pathologylist, { type, payload }) {
    switch (type) {
        case types.PATHOLOGYLIST_REQUEST:
            return {
                ...initialState.pathologylist,
                isLoading: true,
                isStatus: 'request',
                request: payload,
                successResponse: {},
                errorResponse: {}
            };
        case types.PATHOLOGYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isStatus: 'success',
                successResponse: payload,
                request: {}
            };
        case types.PATHOLOGYLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                successResponse: {},
                errorResponse: payload,
                request: {}
            };
        default:
            return state;
    }
}