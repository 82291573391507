import React from 'react';
import AuthService from '../services/authService';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import Notification from '../utils/notification';

class ShowPoojaPoints extends React.Component {
    state = {
        total_points: 0
    }
    componentDidMount() {
        this.props.loaderShow();
        AuthService.getPoojaLoyaltyPoints(this.props.patient.patient_id).then(resp => {
            this.props.loaderHide();
            this.setState({ ...resp.data })
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }
    render() {
        return <section className="middle-wrapper mt-3">
            <div className="container">
                <div className="profile-detail box col-12">
                    <div className="col-24">
                        <div className="profile-title">
                            <i className="patient-contact-icon"></i>
                            <h4>Pooja Points</h4>
                        </div>
                        <div className="patient-detail">
                            <p><span>Balance Point :</span> <span>{this.state.total_points} </span></p>
                            <p><span>View Point :</span> <span> <a href="https://consumer.poojapoints.com/patient/login" target="_blank">Click here</a></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

const mapActionsToProps = ({
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide
})
export default connect(null, mapActionsToProps)(ShowPoojaPoints);