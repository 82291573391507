import React from 'react';
import { connect } from 'react-redux';
import * as AppointmentAction from '../../actions/appointmentAction'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from 'react-bootstrap';
import ScheduledAppointment from '../../components/Appointment/ScheduledAppointment';
import PastAppointment from '../../components/Appointment/PastAppointment';
import { isEmpty } from 'lodash';
import moment from 'moment';

class PatientAppointmentList extends React.Component {
    state = {
        key: "scheduled"
    }
    componentDidMount() {
        this._initialCall();
    }
    _initialCall = () => {
        this.props.paginate({ filter: { type: 'scheduled' } });
    }
    pastAppointments = () => {
        this.props.paginate({ filter: { type: "past-record" } });
    }
    _onChangeTab = (key) => {
        if (key === "scheduled") {
            this._initialCall();
        }
        if (key === "past-record") {
            this.pastAppointments()
        }
        this.setState({ key })
    }
    render() {
        const { successResponse } = this.props.appointments
        return <React.Fragment>
            <Helmet>
                <title>Appointment</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header pb-3">
                        <Link className="white-bg" to="/dashboard"><span>&#8249;</span> Back</Link>
                        <span className="">
                            <span className="font-color-blue font-medium">Appointment Management</span>
                            <Link to="/appointments/schedule" className="btn btn-primary float-right">Schedule Appointment</Link>
                        </span>
                    </div>
                    <Tabs defaultActiveKey="scheduled" className="custom_tabs" onSelect={this._onChangeTab}>
                        <Tab eventKey="scheduled" title="Scheduled">
                            {!isEmpty(successResponse) && this.state.key === "scheduled" && <ScheduledAppointment
                                getAppointments={this._initialCall}
                                appointments={successResponse}
                                history={this.props.history}
                            />}
                        </Tab>
                        <Tab eventKey="past-record" title="Past Record">
                            {this.state.key === "past-record" && !isEmpty(successResponse) &&
                                <PastAppointment
                                    appointments={successResponse}
                                />
                            }
                        </Tab>
                    </Tabs>
                </div>
            </section>

        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    appointments: state.appointment
});
const mapActionsToProps = {
    paginate: AppointmentAction.patientAppointments
};
export default connect(mapStateToProps, mapActionsToProps)(PatientAppointmentList)