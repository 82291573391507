import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.authActions, { type, payload }){
    switch(type){
        case types.EDITPROFILE_REQUEST:
            return{
                ...initialState.authActions,
            isLoading: true,
            isStatus: 'request',
            request: payload,
            response: {}
            };
        case types.EDITPROFILE_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isStatus: 'success',
                response: payload,
                request: {}
            };
        case types.EDITPROFILE_FAILURE:
            return{
                ...state,
                isLoading: false,
                isStatus: 'error',
                response: payload,
                request: {}
            };
        default:
            return state;
    }
}