import React from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import * as AuthAction from "../../actions/authAction";
import Loader from '../../components/Loader';

class ChangePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            current_password: '',
            password: '',
            password_confirmation: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator({
            validators: {
                password: {
                    message: 'The password must contain atleast 1 uppercase letter, 1 lower case, 1 special character and must be of 8 digits',
                    rule: (val, params, validator) => {
                        return (
                          validator.helpers.testRegex(
                            val,
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                          ) && params.indexOf(val) === -1
                        );
                    }
                }
            }
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } 
        
        this.props.changePassword(this.state, this.props.history);
    }

    render() {
        return (
            <div className="middle-wrapper">
                <Loader/>
                <div className="container flex v-center">
                    <div className="form-div">
                        <h2>Change Your Password</h2>
                        <div>
                            <form onSubmit={this.handleSubmit} class="form">
                                <div className="form-field">
                                    <input type="password" className="form-control" placeholder="Current password" id="current_password" name="current_password" onChange={this.handleChange} />
                                    {this.validator.message('current_password', this.state.current_password, 'required')}
                                </div>
                                < div className = "form-field input-tooltip" >
                                    <input type="password" className="form-control" placeholder="New password" id="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                    {this.validator.message('password', this.state.password, 'required|password')}
                                    
                                </div>
                                <div className="form-field">
                                    <input type="password" className="form-control" placeholder="Confirm new password" id="password_confirmation" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} />
                                    {this.validator.message('confirm_password', this.state.password_confirmation, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
                                </div>
                                
                                <div className="form-group clearfix">
                                    <button type="submit" className="btn btn-primary">Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="banner">
                        <img src="images/banner.png" alt="banner" />
                    </div>  
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = ({
    changePassword: AuthAction.changePassword
})

export default connect(mapStateToProps, mapActionsToProps)(ChangePasswordPage);