import React from 'react';
import Appointment from './Appointment';
import { connect } from 'react-redux';
import * as loaderAction from '../../actions/loaderAction';
import { getDateFormat } from '../../utils/helpers';

class ScheduledAppointment extends React.Component {
    render() {
        const { appointments } = this.props;
        return <div className="past_aptm">
            {appointments.data.length > 0 ? appointments.data.map(appointment => (
                <React.Fragment>
                    <p className="aptm_title_main"><span className="aptm_title_date"><span>Date:</span> {getDateFormat(appointment.date)}</span></p>
                    <Appointment
                        appointment={appointment}
                        key={appointment.id}
                        type="past-record"
                        handleDeleteAppointment={this.handleDeleteAppointment}
                    />
                </React.Fragment>

            )) : <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No Record Found</th>
                        </tr>

                    </thead>
                </table>}
        </div>
    }
}
const mapActipnsToProps = {
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
}
export default connect(null, mapActipnsToProps)(ScheduledAppointment)