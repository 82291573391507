import React from 'react';

class ViewGynaecologyHistory extends React.Component {
    render() {
        let { gynaecological_history, reproductive_history, allergies } = this.props.obstetrics_gynaecology;
        return (
            <div className="content-wrapper">
                <h6>Obstetrics and Gynaecology History</h6>
                <div className="content-wrapper">
                    <h6>Reproductive History</h6>
                    <ul className="details-listing">
                        <li>Number of Pregnancies: <span>{reproductive_history.pregnancies_number ? reproductive_history.pregnancies_number : '--'}</span></li>
                        <li>Number of Miscarriage: <span>{reproductive_history.miscarrages_number ? reproductive_history.miscarrages_number : '--'}</span></li>
                        <li>Number of Abortions: <span>{reproductive_history.abortions_number !== "" ? reproductive_history.abortions_number : '--'}</span></li>
                    </ul>
                </div>
                <div className="content-wrapper">
                    <h6>Gynaecological History</h6>
                    <ul className="details-listing">
                        <li>Age of first period: <span>{gynaecological_history.age_of_first_period ? gynaecological_history.age_of_first_period : '--'}</span></li>
                        <li>Regular Period? <span>{gynaecological_history.is_regular_periods === true ? 'Yes' : (gynaecological_history.is_regular_periods === false ? 'No' : '--')}</span></li>
                        <li>Duration of Period: <span>{gynaecological_history.bleeding_between_periods ? gynaecological_history.bleeding_between_periods : '--'}</span></li>
                        <li>Contraception: <span>{gynaecological_history.contraception ? gynaecological_history.contraception : '--'}</span></li>
                    </ul>
                    <ul className="details-listing">
                        <li>Menopause: <span>{gynaecological_history.is_menopause === true ? 'Yes' : (gynaecological_history.is_menopause === false ? 'No' : '--')}</span></li>
                        {gynaecological_history.is_menopause === true && <li>Menopause Comment :
                            <span>{gynaecological_history.menopause_comment ? gynaecological_history.menopause_comment : '--'}</span>
                        </li>}
                        <li>Menopause start date: <span>{gynaecological_history.menopause_age ? gynaecological_history.menopause_age : '--'}</span></li>
                        <li>Pap Smear: <span>{gynaecological_history.pap_smear ? gynaecological_history.pap_smear : '--'}</span></li>
                        <li>Pap Smear Test Date: <span>{gynaecological_history.pap_smear_test_date ? gynaecological_history.pap_smear_test_date : '--'}</span></li>
                    </ul>
                </div>
                <div className="content-wrapper">
                    <h6>Allergies to Contraceptives</h6>
                    <ul className="details-listing">
                        <li>Allergies to medications/substances: <span>{allergies.allergies ? allergies.allergies : '--'}</span></li>
                        <li>Ever been hospitalized for allergic reaction? <span>{allergies.is_hospitalized_allergic_reaction === true ? 'Yes' : (allergies.is_hospitalized_allergic_reaction === false ? 'No' : '--')}</span></li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default ViewGynaecologyHistory