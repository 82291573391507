import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { fullAddressStr } from '../../utils/helpers';
import { isEmpty } from 'lodash';
import moment from 'moment'

export const SelectPatient = ({ selected_patient, patients, handleSelectPatient, appointment_type }) => {
    return <React.Fragment>
        <Form.Label className="col-3 align-self-center required">Select Patient:</Form.Label>
        <Form.Control as="select" className="font-13 col-9" onChange={handleSelectPatient} value={selected_patient} name="patient_id" disabled={isDisabled(appointment_type)}>
            <option value=''>Select Patient</option>
            {!isEmpty(patients) && patients.data.map(patient => (
                <option value={patient.patient_id} key={patient.patient_id}>{patient.full_name}</option>
            ))}
        </Form.Control>
    </React.Fragment>
}

export const SelectSpecialization = ({ specializations, selected_specialization, handleSpecializationChange, appointment_type }) => {
    return <React.Fragment>
        <Form.Label className="col-3 align-self-center required">Select Specialization:</Form.Label>
        <Form.Control as="select" className="font-13 col-9" value={selected_specialization} name="specialization" onChange={(e) => handleSpecializationChange(e.target.value)} disabled={isDisabled(appointment_type)}>
            <option value="">Select Specialization</option>
            {Object.keys(specializations).map(function (key) {
                return <option key={key} value={specializations[key]}>
                    {specializations[key]}
                </option>;
            })}
        </Form.Control>
    </React.Fragment>
}

export const SelectDoctor = ({ selected_doctor, doctor_name, doctors, handleDoctorChange, appointment_type }) => {
    return <React.Fragment>
        <Form.Label className="col-3 align-self-center required">Select Doctor:</Form.Label>
        {appointment_type !== 'reschedule' ? <Form.Control as="select" className="font-13 col-9" name="doctor_profile_id" value={selected_doctor} onChange={handleDoctorChange}>
            <option value="">Select Doctor</option>
            {doctors && doctors.map(doctor => (
                <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
            ))}
        </Form.Control> : <Form.Control as="select" className="font-13 col-9" name="doctor_profile_id" value={doctor_name} disabled>
                <option value={doctor_name}>{doctor_name}</option>
            </Form.Control>}
    </React.Fragment>
}

export const SelectLocation = ({ selected_location, location_name, locations, handleLocationChange, appointment_type }) => {
    return <React.Fragment>
        <Form.Label className="mt-3 col-3 align-self-center required">Select Address:</Form.Label>
        {appointment_type !== 'reschedule' ? <Form.Control as="select" className="mt-3 font-13 col-9" name="location_id" onChange={handleLocationChange} value={selected_location ? selected_location.location_id : ''}>
            <option value="">Select Location</option>
            {locations && locations.map(location => (
                <option value={location.location_id} key={location.location_id}>{fullAddressStr(location)}</option>
            ))}
        </Form.Control> : <Form.Control as="select" className="mt-3 font-13 col-9" name="location_id" value={location_name} disabled>
                <option>{fullAddressStr(selected_location)}</option>
            </Form.Control>}
    </React.Fragment>
}
export const SelectDuration = ({ from, to, handleDuration }) => {
    return <React.Fragment>
        <Form.Label className="mt-3 col-3 align-self-center required">Select Date:</Form.Label>
        <Form.Control
            required
            type="date"
            className="mr-3 i-cale col-3 mt-3 font-13"
            name="from"
            min={moment().format('YYYY-MM-DD')}
            max={to}
            onChange={handleDuration}
            value={from} />
        <Form.Label className="mt-3 align-self-center font-13">To</Form.Label>
        <Form.Control
            required
            type="date"
            className="mx-sm-3 i-cale col-3 mt-3 font-13"
            name="to"
            min={from ? from : moment().format('YYYY-MM-DD')}
            onChange={handleDuration}
            value={to} />
    </React.Fragment>
}

export const Slot = ({ slot, day, slot_id, handleSlotSelection, selected_date }) => {
    return <Col md={24}>
        <div className="title"><span>{day}</span> ({slot.length} Slot)</div>
        <div className="appmnt-slots-data">
            <ul className="row list-inline">
                {slot.map(item => (
                    <li className="col-md-3" key={item.id}>
                        <a href="javascript:;" onClick={(e) => handleSlotSelection(item)} className={getClassName(item, slot_id, selected_date)}>{item.start_time_12hour} To {item.end_time_12hour}</a>
                    </li>
                ))}
            </ul>
        </div>
    </Col>
}
function isDisabled(appointment_type) {
    return appointment_type && appointment_type === 'reschedule' ? 'disabled' : '';
}
function getClassName(appointment, slot_id, selected_date) {
    let diff = moment(appointment.start_time, 'HH:mm:ss').diff(moment(), 'minutes')
    if (moment().isSame(selected_date, 'day') && diff <= 120) {
        return "blocked"
    }

    return appointment.status === 'booked' ? "blocked" : slot_id === appointment.id ? "selected" : ''
}
