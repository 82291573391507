import React from 'react';
import { Helmet } from 'react-helmet';

class ContactUS extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>Contact Us</title>
				</Helmet>

				<section className="middle-wrapper contact-wrapper">
					<div className="container">
						<div className="profile-detail box">
								<div className="patient-detail">
									<h3>Contact Us</h3>
									<p>In case of query, you can reach us via</p>
									<form className="form">
                                      <div className="form-field">
										<label>Email</label>
										<a href="mailto:info@daisy-health.com" style={{textDecoration:"none"}}><input type="email" className="form-control" placeholder="info@daisy-health.com" id="email" name="email" value="" /></a>
									  </div>
									  <div className="form-field">
										<label>Toll Free Line</label>
										<a href="tel:+1 888-429-5765" style={{textDecoration:"none"}}><input type="text" className="form-control" placeholder="+1 888-429-5765" id="contact-number" name="number" value="" /></a>
									  </div>
									</form>
								</div>
						</div>
					</div>
				</section>

			</React.Fragment>);
	}
}

export default ContactUS;