import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';
import _ from 'lodash';

class patientOtp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      otp: '',
      time: {},
      seconds: 59
    };
    this.patient_key = localStorage.getItem('patient_key');
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
    else {

    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.props.patientOtp(this.state.otp, this.patient_key, this.props.history);
  }

  resendOtp(e) {
    e.preventDefault();
    this.props.resendOtp(this.patient_key)
  }


  render() {
    let contact_number = localStorage.getItem('patient_contact_number');
    contact_number = contact_number.replace(/\d(?=\d{3})/g, "*");
    return (<React.Fragment>
      <Helmet>
        <title>Patient Code</title>
      </Helmet>
      <section className="middle-wrapper v-center">
        <div className="container">
          <div className="add-patient">
          <br />
            <h4>Add Patient</h4>
            <i className="patient-code-icon"></i>
            <p className="msg">Enter the Six digit code sent to mobile number<br />{contact_number}</p>
            <form action="" method="" className="form verification-code" onSubmit={this.handleSubmit}>
              <div className="form-field w-100">
                <input type="text" name="otp" placeholder="Enter OTP" id="otp" value={this.state.otp} onChange={this.handleChange} />
                {this.validator.message('Code', this.state.otp, 'required')}
              </div>
              <div className="form-field submit-btn">
                <input type="submit" value="Verify" className="btn" />
              </div>
            </form>
            <p className="text-center">Didn't get the code ? <Link to="#" className="link-color" onClick={this.resendOtp}>Resend code</Link></p>
          </div>
        </div>
      </section>
    </React.Fragment>);
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
  authenticated: state.session.authenticated,
  patients: state.patientOtp,
  patient_code: state.addPatientCode.request
});

const mapActionsToProps = ({
  patientOtp: authAction.patientOtp,
  resendOtp: authAction.resendOtp,
})

export default connect(mapStateToProps, mapActionsToProps)(patientOtp);