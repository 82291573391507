import React from 'react';

class Footer extends React.Component {
    render() {
        return (
        <footer>
            <div className="container">
                <p>Copyright © 2019-{new Date().getFullYear()} Daisy-Health. All rights reserved. <a href="/privacy-policy.html" target="_blank">Privacy Policy</a> and <a href="/terms-conditions.html" target="_blank">Terms & Conditions </a></p>
            </div>
        </footer>
        )
    }
}
export default Footer