import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import configureStore, { history } from './store';
import initAxios from './utils/init-axios';
import 'react-notifications/lib/notifications.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

dotenv.config();
const store = configureStore();

const validateSession = (session) => {
    /* check if your session is still valid */
    return true;
}
const sessionOptions = { refreshOnCheckAuth: true, redirectPath: '/login', driver: 'LOCALSTORAGE', validateSession };
/* Init the session service */
sessionService.initSessionService(store, sessionOptions);

initAxios(store, history);

ReactDOM.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
