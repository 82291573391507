import React from 'react';
import { isEmpty } from 'lodash';

const ViewVital = ({ vital, patient }) => {
    return <div className="content-wrapper">
        <h6>Vitals</h6>
        <ul className="details-listing">
            <li>Height: <span>{vital.patient_height ? parseFloat(vital.patient_height) : '--'} cms</span></li>
            <li>Weight: <span>{vital.patient_weight ? parseFloat(vital.patient_weight) : '--'} kgs</span></li>
            <li>Blood Pressure: <span>{vital.patient_blood_pressure_max ? parseFloat(vital.patient_blood_pressure_max) : '--'}/{vital.patient_blood_pressure_min ? parseFloat(vital.patient_blood_pressure_min) : '--'} mm of Hg</span></li>
            <li>Pulse Rate: <span>{vital.patient_pulse_rate ? parseFloat(vital.patient_pulse_rate) : '--'} BPM</span></li>
            <li>Respiratory Rate: <span>{vital.patient_respiratory_rate ? parseFloat(vital.patient_respiratory_rate) : '--'} BPM</span></li>
        </ul>
        <ul className="details-listing">
            <li>Pulse Ox: <span>{vital.patient_pulse_ox ? parseFloat(vital.patient_pulse_ox) : '--'} SpO2</span></li>
            <li>BMI: <span>{vital.patient_bmi ? parseFloat(vital.patient_bmi) : '--'} kg/m²</span></li>
            <li>Category: <span>
                        {(vital && vital.patient_bmi_category) ? 
                            ( patient.age.year <=19 && patient.gender === "other" ) ?
                            vital.patient_bmi_category &&
                                    vital.patient_gender_other === "male"?  <span>{vital.patient_bmi_category} (Boy)</span> : 
                                    vital.patient_gender_other === "female" ? <span>{vital.patient_bmi_category} (Girl)</span> : vital.patient_bmi_category 
                            :
                            vital.patient_bmi_category
                            : '--'}
                        </span>                    
                    </li>
            <li>Temperature: <span>{vital.patient_temperature ? parseFloat(vital.patient_temperature) : '--'} F</span></li>
            <li>GMR: <span>{vital.patient_rbg ? parseFloat(vital.patient_rbg) : '--'}</span></li>
            <li>Blood Group: <span>{vital.patient_blood_group ? vital.patient_blood_group : '--'}</span></li>
        </ul>
        <ul className="details-listing">
            <li>Allergy: <span>{!isEmpty(vital.patient_allergy) ? vital.patient_allergy.join(", ") : '--'}</span></li>
            <li>Urinalysis: <span>{vital.patient_urinalysis ? vital.patient_urinalysis : '--'}</span></li>
            <li>Comorbidities: <span>{!isEmpty(vital.patient_comorbidities) ? vital.patient_comorbidities.join(", ") : '--'}</span></li>
        </ul>
    </div>
}
export default ViewVital