import React from 'react';

const ShowPathologyTest = ({ pathology_tests }) => {
    return (<div className="content-wrapper">
        <h6>Prescribed Pathology Test</h6>
        <div>
            {pathology_tests.map((pathology_test, index) => (
                <ul className="details-listing full-width" key={index}>
                    <li>
                        <span className="title">Name: </span><span>{pathology_test.test_name} - {pathology_test.test_category ? pathology_test.test_category : '--'}</span>
                    </li>
                    <li>
                        <span className="title">Description:</span>
                        <span>{pathology_test.test_description}</span>
                    </li>
                    <li>
                        <span className="title">Remark:</span>
                        <span>{pathology_test.remark}</span>
                    </li>
                </ul>))}
        </div>
    </div>)
}
export default ShowPathologyTest