import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authAction from '../../../actions/authAction';
import _ from 'lodash';
import * as HELPER from '../../../utils/helpers';
import DataGrid from '../../../components/DataGrid';

class OtherPrescriptionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_prescription_id_array: []
        }
    }

    columns = () => {
        return [
            {
                dataField: 'prescription_id',
                text: 'Prescription Id'
            }, {
                dataField: 'pharmacist_full_name',
                text: 'Pharmacist Name'
            }, {
                formatter: this.dateFormatter,
                text: 'Prescription Date'
            }, {
                formatter: this.actionFormatter,
                text: 'Actions',
                hidden: this.props.hideActions
            }]
    }

    checkboxFormatter = (cell, row) => {
        return (
            <React.Fragment>
            </React.Fragment>);
    }

    actionFormatter = (cell, row) => {
        return <Link to={`/patients/${row.patient_id}/other-prescriptions/${row.prescription_id}`}><i className="view-icon-icon"></i> View</Link>
    }

    dateFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.prescription_date)
        }
    }

    componentDidMount() {
        this.props.getPrescriptionList(this.props.patientId, { sortBy: { sortField: "updated_at", sortOrder: "desc" } })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getPrescriptionList(this.props.patientId, { page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "desc" } });
    };

    render() {
        const { successResponse } = this.props.priscriptionlist;
        return (
            < DataGrid
                columns={this.columns()}
                data={successResponse.data}
                meta={successResponse.meta}
                handleTableChange={this.handleTableChange}
                noDataIndication="No Record Found" />
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    priscriptionlist: state.other_prescription
});

const mapActionsToProps = ({
    getPrescriptionList: authAction.getOtherPrescriptions
})

export default connect(mapStateToProps, mapActionsToProps)(OtherPrescriptionTable);
