import React from 'react';
import * as ViewPersonalHistoryComponents from './ViewPersonalHistoryComponent';

class ViewPersonalHistory extends React.Component {
    render() {
        let { personal_history } = this.props;
        return (
            <div className="content-wrapper">
                <h6>Personl History</h6>
                <ul className="details-listing">
                    <li>Education: <span>{personal_history.education ? personal_history.education : '--'}</span></li>
                </ul>
                <ViewPersonalHistoryComponents.Abuse
                    is_abuse={personal_history.is_abuse}
                    abuse_reason={personal_history.abuse_reason}
                />
                {personal_history.substance.map((item, index) => (
                    <ViewPersonalHistoryComponents.SubstanceAbuse
                        substanceAbuse={item}
                        key={index} />
                ))}

                < ViewPersonalHistoryComponents.Excercise
                    is_exercise={personal_history.is_exercise}
                    exercise_frequency={personal_history.exercise_frequency}
                    is_sleep_snore={personal_history.is_sleep_snore}
                />
                <ViewPersonalHistoryComponents.Sexual
                    personal_history={personal_history}
                />
                <ViewPersonalHistoryComponents.Vaccine
                    hiv_vaccines={personal_history.hiv_vaccines}
                    influenza_vaccines={personal_history.influenza_vaccines}
                    tetanus_vaccacines={personal_history.tetanus_vaccacines}
                />
            </div>
        )
    }
}
export default ViewPersonalHistory