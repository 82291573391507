import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';
import _ from 'lodash';

class addPatientCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            patient_code: '',
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.addPatientCode(this.state.patient_code, this.props.history);
    }

    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Patient Code</title>
            </Helmet>
            <section className="middle-wrapper v-center">
                <div className="container">
                    <div className="add-patient">
                        <br />
                        <h4>Add Patient</h4>
                        <i className="patient-code-icon"></i>
                        <form action="" method="" className="form" onSubmit={this.handleSubmit}>
                            <div className="form-field otp-field">
                                <input type="text" name="patient_code" placeholder="Enter Patient Code" id="patient_code" onChange={this.handleChange} />
                                {this.validator.message('Code', this.state.patient_code, 'required')}
                            </div>
                            <div className="form-field">
                                <input type="submit" value="Send OTP" className="btn" />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    patients: state.addPatientCode
});

const mapActionsToProps = ({
    addPatientCode: authAction.addPatientCode
})

export default connect(mapStateToProps, mapActionsToProps)(addPatientCode);