import React from 'react';
import { connect } from 'react-redux';
import * as authAction from '../../../actions/authAction';
import * as HELPER from '../../../utils/helpers';
import DataGrid from '../../../components/DataGrid';

class RadiologyTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: ''
        }
    }

    columns = () => {
        return [{
            dataField: 'document_name',
            text: 'Report Name',
            style: { wordBreak: 'break-word' }
        }, {
            dataField: 'test_name',
            text: 'Test Name',
            style: { wordBreak: 'break-word' }
        }, {
            formatter: this.dateFormatter,
            text: 'Date'
        }, {
            formatter: this.actionFormatter,
            text: 'Actions'
        }]
    }

    actionFormatter = (cell, row) => {
        return (
            <a href="javascript:void(0)" onClick={this.props.documentDownload.bind(this, row.document_id)} className="icon"><i className="view-icon-icon">
            </i> View</a>
        );
    }

    dateFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.created_at)
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getRadiologyList(this.props.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    componentDidMount() {
        this.props.getRadiologyList(this.props.patientId, {});
    }

    render() {
        const { successResponse } = this.props.radiologylist;
        return (<DataGrid
            columns={this.columns()}
            data={successResponse.data}
            meta={successResponse.meta}
            handleTableChange={this.handleTableChange}
            noDataIndication="No Record Found" />
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    radiologylist: state.radiologylist
});

const mapActionsToProps = ({
    getRadiologyList: authAction.getRadiologyList,
    documentDownload: authAction.documentDownload
})

export default connect(mapStateToProps, mapActionsToProps)(RadiologyTable);
