import React from 'react';
import { Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Header from '../header/Header';
import Footer from '../components/Footer';

class PublicLayout extends React.Component {
    constructor(props) {
        super(props);

        document.body.classList.add("login-forgot-background");
    }
    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route {...rest} render={props => (
                <React.Fragment>
                    <Header {...props} />
                    <NotificationContainer />
                    <Component {...props} />
                    <Footer />
                </React.Fragment>
            )} />
        );
    }
}

export default PublicLayout;