import React from 'react';
import { getDateFormat } from '../../../../utils/helpers'

class ViewSurgicalHistory extends React.Component {
    render() {
        let { item } = this.props;
        return (
            <ul className="details-listing">
                <li>Procedure: <span>{item.procedure ? item.procedure : '--'}</span></li>
                <li>Date: <span>{item.date ? getDateFormat(item.date) : '--'}</span></li>
                <li>Comment: <span>{item.comment ? item.comment : '--'}</span></li>
            </ul>)
    }
}
export default ViewSurgicalHistory