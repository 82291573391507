export default {
    alert: {
        isloading: false,
        status: '',
        type: '',
        message: '',
        errors: []
    },
    loader: {
        isloading: false
    },
    authActions: {
        isLoading: false,
        isStatus: '',
        request: {},
        response: {}
    },
    addPatientCode: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        response: {}
    },
    patientOtp: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        response: {}
    },
    patients: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    patient: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {},
        patientId: ''
    },
    priscriptionlist: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    pathologylist: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    radiologylist: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    prescriptiondetail: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    certificateList: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    notifications: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    list: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
    historicDataList: {
        isLoading: false,
        isStatus: '',
        request: {},
        successResponse: {},
        errorResponse: {}
    },
}