import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authAction from '../../../actions/authAction';
import _ from 'lodash';
import * as HELPER from '../../../utils/helpers';
import DataGrid from '../../../components/DataGrid';

class PrescriptionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_prescription_id_array: []
        }
    }

    columns = () => {
        return [
            {
                text: "",
                formatter: this.checkboxFormatter,
                hidden: this.props.hideCheckbox,
            },
            {
                dataField: 'prescription_id',
                text: 'Prescription Id'
            }, {
                dataField: 'doctor.full_name',
                text: 'Doctor Name'
            }, {
                dataField: 'doctor.specialization',
                text: 'Specialization'
            }, {
                formatter: this.dateFormatter,
                text: 'Prescription Date'
            }, {
                formatter: this.actionFormatter,
                text: 'Actions',
                hidden: this.props.hideActions
            }]
    }

    checkboxFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <input type="checkbox" id={"checkbox" + row.prescription_id} name="select_prescription" value={row.prescription_id} onClick={e => this.selectedPrescription(e, row.prescription_id)} checked={(!_.isEmpty(this.state.selected_prescription_id_array) && this.state.selected_prescription_id_array.includes(row.prescription_id)) ? 'checked' : ''} />
                <label htmlFor={"checkbox" + row.prescription_id}></label>
            </React.Fragment>);
    }

    actionFormatter = (cell, row) => {
        return <Link to={`/patients/` + this.props.patientId + `/prescriptions/${row.prescription_id}`}><i className="view-icon-icon"></i> View</Link>
    }

    dateFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.prescription_date)
        }
    }

    selectedPrescription = (event, prescription_id) => {
        const selected_prescription_id_array = this.state.selected_prescription_id_array
        if (event.target.checked) {
            selected_prescription_id_array.push(prescription_id);
            this.setState({
                selected_prescription_id_array: selected_prescription_id_array,
            })
        }
        if (!event.target.checked) {
            _.pull(selected_prescription_id_array, prescription_id)
            this.setState({
                selected_prescription_id_array: selected_prescription_id_array,
            })
        }
        this.props.handleCheckedPrescription(this.state.selected_prescription_id_array)
    }

    componentDidMount() {
        this.props.getPrescriptionList(this.props.patientId, {})
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getPrescriptionList(this.props.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    render() {
        const { successResponse } = this.props.priscriptionlist;
        return (
            <DataGrid
                columns={this.columns()}
                data={successResponse.data}
                meta={successResponse.meta}
                handleTableChange={this.handleTableChange}
                noDataIndication="No Record Found" />
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    priscriptionlist: state.priscriptionlist
});

const mapActionsToProps = ({
    getPrescriptionList: authAction.getPrescriptionList
})

export default connect(mapStateToProps, mapActionsToProps)(PrescriptionTable);
