import React from 'react';
import AuthService from '../../services/authService';
import PharmacistInfo from '../../components/PharmacistInfo';
import Notification from '../../utils/notification';
import { Link } from 'react-router-dom';
import { getDateFormat } from '../../utils/helpers';
import { Helmet } from 'react-helmet';
import { isEmpty, capitalize } from 'lodash';
import ShowMedicineBlock from '../../components/Prescription/ShowMedicineBlock';
import * as LoaderAction from '../../actions/loaderAction';
import { connect } from 'react-redux';

class OtherPrescriptionDetail extends React.Component {
    state = {
        patientId: '',
        prescriptionId: '',
        prescription: null
    }
    componentDidMount() {
        const { params: { patientId, prescriptionId } } = this.props.match;
        this.props.showLoader();
        AuthService.showOtherPrescription(patientId, prescriptionId).then(response => {
            this.setState({
                patientId: patientId,
                prescriptionId: prescriptionId,
                prescription: response.data
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        const { prescription } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Other Prescription Info</title>
                </Helmet>
                {!isEmpty(prescription) && <section className="middle-wrapper v-center">
                    <div className="container">
                        <div className="list-header">
                            <Link to={"/patients/" + this.state.patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Prescription List</Link>
                            <span className="prescription-details">Prescription Id: <i>{prescription.prescription_id}</i></span>
                            <span className="date">Prescription Date: <i>{getDateFormat(prescription.prescription_date)}</i></span>
                        </div>
                        <section>
                            <div className="prescription-list">
                                {/* Pharmacist info */}
                                {prescription.pharmacist && <PharmacistInfo
                                    pharmacist={prescription.pharmacist}
                                />}
                                {/* Patient Info */}
                                {prescription.patient && <div className="content-wrapper">
                                    <h6>Patient Info</h6>
                                    <ul className="details-listing">
                                        <li>Name: <span>{prescription.patient.full_name}</span></li>
                                        <li>Age: <span>{prescription.patient.age.year + " year " + prescription.patient.age.month + ' month'}</span></li>
                                        <li>Gender: <span>{capitalize(prescription.patient.gender)}</span></li>
                                    </ul>
                                </div>}
                                {!isEmpty(prescription.prescription_medicine_items) && <div className="content-wrapper">
                                    <h6>Prescribed Medication</h6>
                                    <ShowMedicineBlock medicines={prescription.prescription_medicine_items} />
                                </div>}
                            </div>
                        </section>
                    </div>
                </section>}
            </React.Fragment>
        )
    }
}
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(null, mapActionsToProps)(OtherPrescriptionDetail);