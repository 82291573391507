import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as authAction from '../../actions/authAction';
import _ from 'lodash';
import * as HELPERS from '../../utils/helpers';
import AuthService from '../../services/authService';
import Notification from '../../utils/notification';

class DashboardPage extends React.Component {

	constructor(props) {
		super(props);
		this.props.getPatient();

		this.state = {
			deletePatientCode: null
		}
	}
	getPatientData() {
		return this.props.patients.successResponse.data.map((data) => {
			return (<li className="grid-list-4">
				<div className="patient-title">
					{data.profile_image ? <span className="title-sort"><img className="rounded-circle" src={data.profile_image.thumbnail_url} alt="profile" /></span> : <span className="title-sort"></span>}
					<h6 className="title">{_.startCase(data.full_name)}</h6>
					<a className="delete-profile" href='#'>
						<img src="images/dustbin.png" alt="delete" onClick={this.showConfirmation.bind(this, data.patient_code)} />
					</a>
					{/* <small className="location"><i className="maps-and-flags-icon"></i>{data.address.city}</small> */}
				</div>
				<div className="patient-detail">
					<ul>
						<li><span>Gender: </span><span className="dark">{_.upperFirst(data.gender)}</span></li>
						<li><span>Date of Birth: </span><span className="dark">{HELPERS.getDateFormat(data.date_of_birth)}</span></li>
					</ul>
				</div>
				<div className="patient-link">
					<Link to={`/patients/${data.patient_id}`} className="btn no-bg"><i className="user-icon"></i>View Full Profile</Link>
					<Link to={`/patients/${data.patient_id}/prescriptions`} className="btn"> <i className="record-icon"></i>Record</Link>
				</div>
			</li>
			);
		})
	}

	componentDidMount() {
		document.body.classList.add('body-bg-gray');
	}

	showConfirmation = (patient_code) => {
		this.setState({
			deletePatientCode: patient_code
		})
	}

	onYesConfirm = () => {
		const patientCode = this.state.deletePatientCode;
		this.setState({
			deletePatientCode: null
		})

		if (patientCode) {
			AuthService.patientDetach(patientCode)
				.then(resp => {
					Notification.show('success', { message: "User successfully detach" });
					this.props.getPatient();
				})
				.catch(error => {
					if (error.response) {
						Notification.show('error', error.response.data);
					}
				});
		}
	}

	onNoConfirm = () => {
		this.setState({
			deletePatientCode: null
		})
	}

	render() {
		return (<React.Fragment>
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			{!_.isEmpty(this.props.patients.successResponse) && !_.isEmpty(this.props.patients.successResponse.data) ? (<section className="middle-wrapper">
				<div className="container">
					<div className="title-wrapper">
						<h4>Profile</h4>
						<Link to="/patient-code" className="btn no-bg"><i className="add-user-icon"></i>Add Patient</Link>
					</div>
					<ul className="grid profile">
						{this.getPatientData()}
					</ul>
				</div>
			</section>) : (
					<section className="middle-wrapper v-center">
						<div className="container">
							<div className="add-patient">
								<i className="add-patient-icon"></i>
								<p className="msg">If this is your first visit to the patient access</p>
								<Link to="/patient-code" className="btn"> ADD PATIENT</Link>
							</div>
						</div>
					</section>
				)}

			<div className="overlay-alert" >
				<div className="overlay" style={{ display: "none" }} ></div>
				<div className="alert-container" style={{ display: "none" }}>
					<div className="alert-content">
						<img src="images/alert-icon.png" alt="alert" />
						<h6>Alert</h6>
						<p className="modal-title">Do you really want to delete?</p>
						<button className="confirm-btn active cancel" onClick={this.onNoConfirm}>No</button>
						<button className="confirm-btn cancel" onClick={this.onYesConfirm}>Yes</button>
					</div>
				</div>
			</div>

		</React.Fragment>);
	}
}

const mapStateToProps = (state) => ({
	user: state.session.user,
	authenticated: state.session.authenticated,
	patients: state.patients
});

const mapActionsToProps = ({
	getPatient: authAction.getPatient
})

export default connect(mapStateToProps, mapActionsToProps)(DashboardPage);