import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { startCase } from 'lodash';
import DataGrid from '../components/DataGrid';
import { getDateFormat } from '../utils/helpers'
import * as authAction from '../actions/authAction';
import authService from "../services/authService";
import * as LoaderAction from '../actions/loaderAction';
import Notification from '../utils/notification';
import SelectPage from '../components/SelectPage';

class PatientCertificates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId,
        }
    }

    columns = () => {
        return [{
            dataField: 'medical_certificate_type',
            text: 'Certificate Name',
            formatter: this.typeFormatter,
        }, {
            formatter: this.dateFormatter,
            text: 'Issued Date'
        }, {
            formatter: this.actionFormatter,
            text: 'Actions',
        }]
    }

    dateFormatter = (_cell, row) => {
        if (row) {
            return getDateFormat(row.updated_at)
        }
    }

    typeFormatter = (_cell, row) => {
        return startCase(row.medical_certificate_type);
    };

    actionFormatter = (_cell, row) => {
        return <Link className="icon" onClick={() => this.actionClick(row)} ><i className="view-icon-icon"></i></Link>
    }

    actionClick = (data) => {
        this.props.showLoader();
        authService.downloadCertificate(data.medical_certificate_id).then(resp => {
            this.props.hideLoader();
            window.open(resp.data.url);
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data);
        });
    }

    componentDidMount() {
        if (this.props.patient.patientId !== this.state.patientId) {
            this.props.getPatientById(this.state.patientId);
        }
        this.props.getPatientCertificates(this.state.patientId, { sortBy: { sortField: "updated_at", sortOrder: "desc" } })
    }

    handleTableChange = (type, { page, sizePerPage, sortField = "updated_at", sortOrder = "desc" }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getPatientCertificates(this.state.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    render() {
        const { successResponse } = this.props.certificate_list;
        const { patientId } = this.state;
        let patient_detail = this.props.patient.successResponse;
        return (<React.Fragment>
            <Helmet>
                <title>Prescription</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header">
                        <Link to={"/patients/" + patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Back</Link>
                        <span className="patient-name">{patient_detail.full_name}</span>
                        <SelectPage history={this.props.history} patientId={patientId} />
                    </div>
                    <section>
                        <div className="container prescription-tabs">
                            <ul className="tabs">
                                <li className="tab-link active" data-tab="tab-1">Certificates</li>
                            </ul>
                            <div id="tab-1" className="tab-content active">
                                <div className="">
                                    < DataGrid
                                        columns={this.columns()}
                                        data={successResponse.data}
                                        meta={successResponse.meta}
                                        handleTableChange={this.handleTableChange}
                                        noDataIndication="No Record Found" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </React.Fragment>);
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    certificate_list: state.certificate_list,
    patient: state.patient
});
const mapActionsToProps = {
    getPatientCertificates: authAction.getPatientCertificates,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getPatientById: authAction.getPatientById
};

export default connect(mapStateToProps, mapActionsToProps)(PatientCertificates);
