import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';

const { ExportCSVButton } = CSVExport;

class DataGrid extends React.Component {
    static defaultProps = {
        loading: false,
        pagination: true,
        noDataIndication: " ",
        data: [],
        meta: {
            current_page: 0,
            per_page: 0,
            total: 0
        },
    }


    render() {
        return (<React.Fragment>
            <ToolkitProvider
                bootstrap4
                search
                keyField="id"
                data={this.props.data}
                columns={this.props.columns}
                exportCSV
            >
                {
                    props => (
                        <PaginationProvider
                            pagination={paginationFactory({
                                custom: true,
                                page: parseInt(this.props.meta.current_page),
                                sizePerPage: parseInt(this.props.meta.per_page),
                                totalSize: parseInt(this.props.meta.total),
                            })}
                        >{
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                        <React.Fragment>
                                            <div className="data-grid-table">
                                                {/*<div>
                                                    {<span className="float-right">
                                                        {<Search.SearchBar {...props.searchProps} delay={1000} />}
                                                    </span>}
                                                </div>*/}

                                                {this.props.export_csv && <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>}
                                                <BootstrapTable
                                                    classes={"table table-responsive " + (this.props.classes ? this.props.classes : '')}
                                                    bordered={false}
                                                    {...props.baseProps}
                                                    remote
                                                    onTableChange={this.props.handleTableChange}
                                                    {...paginationTableProps}
                                                    noDataIndication={this.props.loading == false ? this.props.noDataIndication : " "}
                                                    rowClasses={this.props.rowClasses}
                                                    loading={this.props.loading}
                                                    overlay={overlayFactory({ spinner: true, styles: { overlay: (base) => ({ ...base, background: 'rgba(158, 158, 158, 0.5)' }) } })}

                                                />
                                                {(this.props.pagination && this.props.meta.total > 0) &&
                                                    <div className="row mt-3">
                                                        <div className="col-24">
                                                            <span className="float-left">
                                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                                            </span>
                                                            <span className="float-right">
                                                                <PaginationListStandalone {...paginationProps} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )
                            }
                        </PaginationProvider>

                    )
                }
            </ToolkitProvider>
        </React.Fragment>);
    }
}

export default DataGrid;
