import React from 'react';

const PharmacistInfo = ({ pharmacist }) => {
    return (
        <div className="content-wrapper">
            <h6>Pharmacist Info</h6>
            <ul className="details-listing">
                <li>Name: <span>{pharmacist.full_name}</span></li>
            </ul>
        </div>
    )
}
export default PharmacistInfo