import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.authActions, {type, payload}){
    switch(type){
        case types.LOGIN_REQUEST:
            return {
                isLoading: true,
                isStatus: 'request',
                request: payload,
                response: {},
            };
        case types.LOGIN_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isStatus: 'success',
                response: payload,
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isStatus: 'error',
                response: payload,
            };
        default:
            return state;
    }
}