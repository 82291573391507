import React from 'react';
import Loader from './Loader';

class Content extends React.Component {
    render() {
        return (
            <section className="container-fluid">
                <Loader />
                {this.props.children}
            </section>
        );
    }
}

export default Content;