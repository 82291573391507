import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';
import { Helmet } from 'react-helmet';
import * as Config from '../../config';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import AuthService from '../../services/authService';
import _ from "lodash";
import moment from 'moment';
import Notification from '../../utils/notification';

class RegistrationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            daisyPatient: "no",
            daisyPatientVerify: "",
            covidTests: [],
            service_provider: "",
            formData: {},
            patient: "",
            disabled: false,
            insurance_type: [],
                is_no: false,
                is_other: false,
                is_private_sagicore: false,
                is_private_medicus: false,
                is_private_canopy: false,
                is_jadep: false,
                is_nhf: false,
                is_goj: false,
                jadep_number: "",
                nhf_number: "",
                goj_number: "",
                isNoChecked: false,
                isPrivateChecked: false,
                isJadepChecked: false,
                isNHFChecked: false,
                isGOJChecked: false,
                isJadepDisabled: true,
        };
        this.recaptchaRef = React.createRef();
        this.patientVerifyValidator = new SimpleReactValidator();
        this.newPatientValidator = new SimpleReactValidator({
            validators: {
                insurance_type: {
                    message: 'Select atleast one insurance type or select No',
                    rule: (val) => {
                        return val.length > 0;
                    },
                    required: true
                }
            }
        });
        this.existingPatientValidator = new SimpleReactValidator();

        this.toggleShow = this.toggleShow.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.service_provider) {

            this.setState({ service_provider: this.props.match.params.service_provider }, () => {
                this.getCovidTestList();
            })
        }
    }

    resetState = () => {
        this.setState({
            daisyPatient: "no",
            daisyPatientVerify: "",
            formData: {
                address_line_1: ""
            },
            patient: "",
            is_no: false,
            is_private_sagicore: false,
            is_private_medicus: false,
            is_private_canopy: false,
            is_jadep: false,
            is_nhf: false,
            is_goj: false,
            is_other: false,
            jadep_number: "",
            nhf_number: "",
            goj_number: "",
            private_insurance_sagicore_number: "",
            private_insurance_medicus_number: "",
            private_insurance_canopy_number: "",
        });
    }

    getCovidTestList = () => {
        AuthService.getCovidTestList(this.state.service_provider)
            .then(resp => {
                this.setState({ covidTests: resp.data.data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    verifySubmit = (evt) => {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit = () => {
        let formData = this.state.formData;

        let submitData = {}
        submitData['service_provider'] = this.state.service_provider;
        submitData['patient_type'] = this.state.daisyPatient === "yes" ? "old" : "new";
        submitData['test_reason'] = formData['test_reason'];
        submitData['email'] = formData['email'];
        submitData['tests'] = formData['testType'];

        if (this.state.daisyPatient === "no") {
            if (!this.newPatientValidator.allValid()) {
                this.newPatientValidator.showMessages();
                this.forceUpdate();
                return false;
            }

            submitData['contact_number'] = formData['contact_number'];
            submitData['dob'] = formData['dob'];
            submitData['gender'] = formData['gender'];
            submitData['patient_name'] = formData['patient_name'];
            submitData['maiden_name'] = formData['mother_maiden_name'];
            submitData['alt_contact'] = formData['alt_contact_number'];
            submitData['place_of_birth'] = formData['place_of_birth'];
            submitData['insurance_type'] = {
                'insurance_type': this.state.insurance_type,
                'jadep_number': this.state.jadep_number,
                'nhf_number': this.state.nhf_number,
                'goj_number': this.state.goj_number,
                'sagicore_number': this.state.private_insurance_sagicore_number ? this.state.private_insurance_sagicore_number : '',
                'medicus_number': this.state.private_insurance_medicus_number ? this.state.private_insurance_medicus_number : '',
                'canopy_number': this.state.private_insurance_canopy_number ? this.state.private_insurance_canopy_number : ''
            };
            submitData['address'] = {
                'address_line_1': formData['address_line_1'],
                'parish': formData['parish'],
                'city': formData['city']
            };
        } else {
            if (!this.existingPatientValidator.allValid()) {
                this.existingPatientValidator.showMessages();
                this.forceUpdate();
                return false;
            }
            submitData['patient_id'] = this.state.patient.patient_id;
        }

        if (formData['test_reason'] === "travel") {
            submitData['questionnaire'] = { [formData['test_reason']]: { 'que': 'Destination', 'ans': formData['destination'] ? formData['destination'] : "" } };
        } else if (formData['test_reason'] === "symphomatic") {
            submitData['questionnaire'] = { [formData['test_reason']]: { 'que': 'Symptoms', 'ans': formData['symptoms'] ? formData['symptoms'] : "" } };
        } else {
            submitData['questionnaire'] = { [formData['test_reason']]: { 'que': 'Others', 'ans': formData['others'] ? formData['others'] : "" } };
        }

        this.setState({ disabled: true })
        AuthService.createCovidTest(submitData)
            .then(resp => {
                Notification.show('success', { 'message': 'Success' })
                this.resetState();
                this.resetForm();
                this.setState({ disabled: false })
            })
            .catch(error => {
                console.log(error)
                this.setState({ disabled: false })
            });
    }

    handleDaisyPatient = (e) => {
        this.setState({ daisyPatient: e.target.value }, () => {
            this.setState({
                daisyPatientVerify: "",
                formData: {}
            })

            this.newPatientValidator.hideMessages();
            this.existingPatientValidator.hideMessages();
        });
    }

    handleChange = (e) => {
        if (e.target.name === 'dob') {
            const patientAge = moment().diff(e.target.value, 'years');

            if (patientAge >= 60) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [e.target.name]: e.target.value,
                    },
                    isJadepDisabled: false
                });
            } else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [e.target.name]: e.target.value,
                    },
                    isJadepDisabled: true,
                    jadep_number: '',
                    is_jadep: false
                });
            }
            return true;
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    formHandleChange = (e) => {

        if (e.target.name === 'test_reason') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    'destination': '',
                    'symptoms': '',
                    'others': ''
                }
            });
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleInsuranceTypeChange = (e) => {
        let insurance_type = this.state.insurance_type;

        const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_other } = this.state;

        if (e.target.checked) {
            insurance_type.push(e.target.value);
        } else {
            if (e.target.value === 'jadep') {
                this.setState({
                    jadep_number: ''
                });
            }
            if (e.target.value === 'nhf') {
                this.setState({
                    nhf_number: ''
                });
            }
            if (e.target.value === 'goj') {
                this.setState({
                    goj_number: ''
                });
            }
            if (e.target.value === 'private-insurance-sagicore') {
                this.setState({
                    private_insurance_sagicore_number: ''
                });
            }
            if (e.target.value === 'private-insurance-medicus') {
                this.setState({
                    private_insurance_medicus_number: ''
                });
            }
            if (e.target.value === 'private-insurance-canopy') {
                this.setState({
                    private_insurance_canopy_number: ''
                });
            }
            _.pull(insurance_type, e.target.value);
        }

        if (e.target.value === 'no') {
            if (e.target.checked) {
                insurance_type = ["no"];
            }
            this.setState({
                is_no: !is_no,
                is_private_sagicore: e.target.unchecked,
                is_private_medicus: e.target.unchecked,
                is_private_canopy: e.target.unchecked,
                is_jadep: e.target.unchecked,
                is_nhf: e.target.unchecked,
                is_goj: e.target.unchecked,
                is_other: e.target.unchecked,
                jadep_number: "",
                nhf_number: "",
                goj_number: "",
                private_insurance_sagicore_number: "",
                private_insurance_medicus_number: "",
                private_insurance_canopy_number: "",
            });
        } else {
            _.pull(insurance_type, "no");
        }

        if (e.target.value === 'private-insurance-sagicore') {
            this.setState({
                is_private_sagicore: !is_private_sagicore,
                is_no: false
            });
        }

        if (e.target.value === 'other') {
            this.setState({
                is_other: !is_other,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-medicus') {
            this.setState({
                is_private_medicus: !is_private_medicus,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-canopy') {
            this.setState({
                is_private_canopy: !is_private_canopy,
                is_no: false
            });
        }

        if (e.target.value === 'jadep') {
            this.setState({
                is_no: false,
                is_jadep: e.target.checked
            });
        }

        if (e.target.value === 'nhf') {
            this.setState({
                is_no: false,
                is_nhf: e.target.checked
            });
        }

        if (e.target.value === 'goj') {
            this.setState({
                is_no: false,
                is_goj: e.target.checked
            });
        }

        this.setState({ insurance_type: insurance_type });
    }

    verifyPatient = () => {
        if (!this.patientVerifyValidator.allValid()) {
            this.patientVerifyValidator.showMessages();
            this.forceUpdate();
            return false;
        }

        AuthService.verifyPatient(this.state.patient_code)
            .then(resp => {
                this.setState({ daisyPatientVerify: "true", patient: resp.data.patient })
            })
            .catch(error => {
                console.log(error)
                this.setState({ daisyPatientVerify: "false" })
            });
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    resetForm = (type) => {
        this.setState({ formData: {} });
        if (this.state.daisyPatient === "no") {
            this.addCovidTestForm.reset();
            this.newPatientValidator.hideMessages();
        } else {
            this.existingCovidTestForm.reset();
            this.existingPatientValidator.hideMessages();
        }
    }

    render() {
        this.newPatientValidator.purgeFields();
        const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_jadep, is_nhf, is_goj, is_other } = this.state;
        return (
            <section class="middle-wrapper">
                <div className="container flex v-center justify-content-center">
                    <Helmet>
                        <title>Covid Test Form</title>
                    </Helmet>
                    <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                    <div className="col-sm-22">
                        <h3 className='text-center mt-4 mb-4'>Covid Test Form</h3>

                        <form className="mt-4">
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Already Daisy Health Patient?</label>
                                <div className="col-sm-12">
                                    <span>
                                        <input type="radio" id="yes" name="daisy-patient" value={"yes"} checked={this.state.daisyPatient === "yes"} className="form-control" onChange={this.handleDaisyPatient} />
                                        <label htmlFor="yes">Yes</label>
                                        <input type="radio" id="no" name="daisy-patient" value={"no"} checked={this.state.daisyPatient === "no"} className="form-control" onChange={this.handleDaisyPatient} />
                                        <label htmlFor="no">No</label></span>
                                </div>
                            </div>
                        </form>

                        {this.state.daisyPatient === "yes" && <form className="mt-2">
                            <div className="form-group row">
                                <label htmlFor="patient_code" className="col-sm-12 col-form-label">Enter your patient code</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="patient_code" name="patient_code" onChange={this.handleChange} />
                                    {this.patientVerifyValidator.message('patient_code', this.state.patient_code, 'required')}
                                    {_.isEmpty(this.state.daisyPatientVerify) ? "" : (this.state.daisyPatientVerify == "true" ? <p className='text-success'>Valid Patient</p> : <p className='text-danger'>Invalid Patient</p>)}
                                </div>
                                <div className="col-sm-2">
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ minWidth: "50px", padding: "6px" }} onClick={e => this.verifyPatient()}>Verify</button>
                                </div>
                            </div>
                        </form>}

                        <hr />

                        {this.state.daisyPatient === "no" && <div id="new-patient">
                            <form className="mt-2" onSubmit={this.verifySubmit} ref={form => this.addCovidTestForm = form}>
                                <div className="form-group row">
                                    <label htmlFor="patient_name" className="col-sm-12 col-form-label">Patient Name *</label>
                                    <div className="col-sm-12">
                                        <input type="text" id="patient_name" name="patient_name" value={this.state.formData.patient_name} className="form-control" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('patient_name', this.state.formData.patient_name, 'required')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mother_maiden_name" className="col-sm-12 col-form-label">Mother's Maiden Name *</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" value={this.state.formData.mother_maiden_name} id="mother_maiden_name" name="mother_maiden_name" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('mother_maiden_name', this.state.formData.mother_maiden_name, 'required|string')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="gender" className="col-sm-12 col-form-label">Gender *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control sm-4" name="gender" id="gender" onChange={this.formHandleChange} >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {this.newPatientValidator.message('gender', this.state.formData.gender, 'required')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="place_of_birth" className="col-sm-12 col-form-label">Place Of Birth *</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" value={this.state.formData.place_of_birth} id="place_of_birth" name="place_of_birth" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('place_of_birth', this.state.formData.place_of_birth, 'required')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="dob" className="col-sm-12 col-form-label">Date of Birth *</label>
                                    <div className="col-sm-12">
                                        <input type="date" id="dob" name="dob" className="form-control" onChange={this.handleChange} />
                                        {this.newPatientValidator.message('date_of_birth', this.state.formData.dob, 'required')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="contact_number" className="col-sm-12 col-form-label">Contact Number *</label>
                                    <div className="col-sm-12">
                                        <input type="number" id="contact_number" name="contact_number" className="form-control" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('contact_number', this.state.formData.contact_number, 'required|phone|size:10')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="alt_contact_number" className="col-sm-12 col-form-label">Alternate Number/Landline Number</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" value={this.state.formData.alt_contact_number} id="alt_contact_number" name="alt_contact_number" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('alt_contact_number', this.state.formData.alt_contact_number, 'phone')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Enter email Address to receive report *</label>
                                    <div className="col-sm-12">
                                        <input type="text" id="email" name="email" className="form-control" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('email', this.state.formData.email, 'required|email')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="insurance_type" className="col-sm-12 col-form-label">Insurance Type *</label>
                                    <div className="col-sm-12">
                                    <div className="checkbox-option-list">
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" id={'insurance_type-no'} name="insurance_type" value="no" onChange={this.handleInsuranceTypeChange} checked={is_no} />
                                            <label htmlFor={'insurance_type-no'}>No</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" id={'insurance_type-jadep'} name="insurance_type" value="jadep" disabled={this.state.isJadepDisabled} onChange={this.handleInsuranceTypeChange} checked={is_jadep} />
                                            <label htmlFor={'insurance_type-jadep'}>JADEP</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" id={'insurance_type-nhf'} name="insurance_type" value="nhf" onChange={this.handleInsuranceTypeChange} checked={is_nhf} />
                                            <label htmlFor={'insurance_type-nhf'}>NHF</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" id={'insurance_type-goj'} name="insurance_type" value="goj" onChange={this.handleInsuranceTypeChange} checked={is_goj} />
                                            <label htmlFor={'insurance_type-goj'}>GOJ</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" id={'insurance_type-private-insurance-sagicore'} name="insurance_type" value="private-insurance-sagicore" onChange={this.handleInsuranceTypeChange} checked={is_private_sagicore} />
                                            <label htmlFor={'insurance_type-private-insurance-sagicore'}>Private Insurance Sagicor</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" id={'insurance_type-private-insurance-medicus'} name="insurance_type" value="private-insurance-medicus" onChange={this.handleInsuranceTypeChange} checked={is_private_medicus} />
                                            <label htmlFor={'insurance_type-private-insurance-medicus'}>Private Insurance Medicus</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" id={'insurance_type-private-insurance-canopy'} name="insurance_type" value="private-insurance-canopy" onChange={this.handleInsuranceTypeChange} checked={is_private_canopy} />
                                            <label htmlFor={'insurance_type-private-insurance-canopy'}>Private Insurance Canopy</label>
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" id={'insurance_type-other'} name="insurance_type" value="other" onChange={this.handleInsuranceTypeChange} checked={is_other} />
                                            <label htmlFor={'insurance_type-other'}>Other</label>
                                        </div>
                                    </div>
                                    {this.newPatientValidator.message('insurance_type', this.state.insurance_type, 'required')}
                                    </div>
                                </div>


                            <div className="form-group row">
                                <label htmlFor="insurance_type" className="col-sm-12 col-form-label"></label>
                                <div className="col-sm-12">

                                {this.state.is_jadep && <div>
                                    <label htmlFor="jadep_number" className="col-form-label">Jadep Number *</label>
                                    <input type="text" className="form-control" value={this.state.jadep_number} id="jadep_number" name="jadep_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('jadep_number', this.state.jadep_number, 'required|string|max:100')}
                                </div>}

                                {this.state.is_nhf && <div>
                                    <label htmlFor="nhf_number" className="col-form-label">NHF Number *</label>
                                    <input type="text" className="form-control" value={this.state.nhf_number} id="nhf_number" name="nhf_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('nhf_number', this.state.nhf_number, 'required|string|max:100')}
                                </div>}

                                {this.state.is_goj && <div>
                                    <label htmlFor="goj_number" className="col-form-label">GOJ Number *</label>
                                    <input type="text" className="form-control" value={this.state.goj_number} id="goj_number" name="goj_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('goj_number', this.state.goj_number, 'required|string|max:100')}
                                </div>}

                                {this.state.is_private_sagicore && <div>
                                    <label htmlFor="goj_number" className="col-form-label">Sagicore Number *</label>
                                    <input type="text" className="form-control" value={this.state.private_insurance_sagicore_number} id="sagicore_number" name="private_insurance_sagicore_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('private_insurance_sagicore_number', this.state.private_insurance_sagicore_number, 'required|string|max:100')}
                                </div>}

                                {this.state.is_private_medicus && <div>
                                    <label htmlFor="goj_number" className="col-form-label">Medicus Number *</label>
                                    <input type="text" className="form-control" value={this.state.private_insurance_medicus_number} id="medicus_number" name="private_insurance_medicus_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('private_insurance_medicus_number', this.state.private_insurance_medicus_number, 'required|string|max:100')}
                                </div>}

                                {this.state.is_private_canopy && <div>
                                    <label htmlFor="goj_number" className="col-form-label">Canopy Number*</label>
                                    <input type="text" className="form-control" value={this.state.private_insurance_canopy_number} id="canopy_number" name="private_insurance_canopy_number" onChange={this.handleChange} />
                                    {this.newPatientValidator.message('private_insurance_canopy_number', this.state.private_insurance_canopy_number, 'required|string|max:100')}
                                </div>}
                                </div>
                            </div>

                                <div className="form-group row">
                                    <label htmlFor="address_line_1" className="col-sm-12 col-form-label">Address *</label>
                                    <div className="col-sm-12">
                                        <textarea maxLength="255" type="text" rows="2" className="form-control" value={this.state.formData.address_line_1} id="address_line_1" name="address_line_1" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('address', this.state.formData.address_line_1, 'required|string|max:100')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="parish" className="col-sm-12 col-form-label">Parish *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control" value={this.state.formData.parish} id="parish" name="parish" onChange={this.formHandleChange}>
                                            <option value="" >Select Parish</option>
                                            {Object.keys(Config.parish).map(function (key) {
                                                return <option className="travelcompany-input" value={key}>
                                                    {Config.parish[key]}
                                                </option>;
                                            })}
                                        </select>
                                        {this.newPatientValidator.message('parish', this.state.formData.parish, 'required')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="city" className="col-sm-12 col-form-label">City *</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" value={this.state.formData.city} id="city" name="city" onChange={this.formHandleChange} />
                                        {this.newPatientValidator.message('city', this.state.formData.city, 'required|string|max:100')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="test_reason" className="col-sm-12 col-form-label">Why are you getting tested? *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control sm-4" name="test_reason" id="test_reason" onChange={this.formHandleChange}>
                                            <option value="">Select Test</option>
                                            <option value="travel">Travel</option>
                                            <option value="symphomatic">Symphomatic</option>
                                            <option value="possible-exposure">Possible Exposure</option>
                                            <option value="quarantine-exit">Quarantine Exit</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {this.newPatientValidator.message('test_reason', this.state.formData.test_reason, 'required')}
                                    </div>
                                </div>

                                {this.state.formData.test_reason === "travel" && <div className="form-group row">
                                    <label htmlFor="destination" className="col-sm-12 col-form-label">Enter Destination</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="destination" name="destination" onChange={this.formHandleChange} />
                                    </div>
                                </div>
                                }

                                {this.state.formData.test_reason === "symphomatic" && <div className="form-group row">
                                    <label htmlFor="symptoms" className="col-sm-12 col-form-label">Enter Symptoms</label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" id="symptoms" name="symptoms" onChange={this.formHandleChange} />
                                    </div>
                                </div>}

                                {(this.state.formData.test_reason && !['travel', 'symphomatic'].includes(this.state.formData.test_reason)) && <div className="form-group row">
                                    <label htmlFor="others" className="col-sm-12 col-form-label">Others</label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" id="others" name="others" onChange={this.formHandleChange} />
                                    </div>
                                </div>
                                }

                                <div className="form-group row">
                                    <label htmlFor="test_type" className="col-sm-12 col-form-label">Select Test Type</label>
                                    <div className="col-sm-12">
                                        {this.state.covidTests.map((test, idx) => (
                                            <React.Fragment key={idx}>
                                                <input type="radio" id={"test-" + idx}  name="testType" value={test.id} className="form-control" onChange={this.formHandleChange} />
                                                <label htmlFor={"test-" + idx} className="mr-4 mt-4">{test.name + " - JMD $" + test.price}</label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>

                                <div className="form-group row pull-right">
                                    <button type="button" className="btn btn-custom mr-4 btn-gray" onClick={this.resetForm} >Clear</button>
                                    <button type="submit" className="btn btn-custom "  disabled={this.state.disabled}>Submit</button>
                                </div>
                            </form>
                        </div>
                        }

                        {(this.state.daisyPatient === "yes" && this.state.daisyPatientVerify == "true") && <div id="existing-patient">
                            <form className="mt-2" onSubmit={this.verifySubmit} ref={form => this.existingCovidTestForm = form}>
                                <div className="form-group row">
                                    <label htmlFor="patient_name" className="col-sm-12 col-form-label">Patient Name *</label>
                                    <div className="col-sm-12">
                                        <input type="text" id="patient_name" className="form-control" disabled value={this.state.patient.full_name} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Enter email Address to receive report *</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="email" name="email" onChange={this.formHandleChange} />
                                        {this.existingPatientValidator.message('email', this.state.formData.email, 'required|email')}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="test_reason" className="col-sm-12 col-form-label">Why are you getting tested? *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control sm-4" name="test_reason" id="test_reason" onChange={this.formHandleChange}>
                                            <option value="">Select Test</option>
                                            <option value="travel">Travel</option>
                                            <option value="symphomatic">Symphomatic</option>
                                            <option value="possible-exposure">Possible Exposure</option>
                                            <option value="quarantine-exit">Quarantine Exit</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {this.existingPatientValidator.message('test_reason', this.state.formData.test_reason, 'required')}
                                    </div>
                                </div>

                                {this.state.formData.test_reason === "travel" && <div className="form-group row">
                                    <label htmlFor="destination" className="col-sm-12 col-form-label">Enter Destination</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="destination" name="destination" onChange={this.formHandleChange} />
                                    </div>
                                </div>}

                                {this.state.formData.test_reason === "symphomatic" && <div className="form-group row">
                                    <label htmlFor="symptoms" className="col-sm-12 col-form-label">Enter Symptoms</label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" id="symptoms" name="symptoms" onChange={this.formHandleChange} />
                                    </div>
                                </div>
                                }

                                {(this.state.formData.test_reason && !['travel', 'symphomatic'].includes(this.state.formData.test_reason)) && < div className="form-group row">
                                    <label htmlFor="others" className="col-sm-12 col-form-label">Others</label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" id="others" name="others" onChange={this.formHandleChange} />
                                    </div>
                                </div>}

                                <div className="form-group row">
                                    <label htmlFor="test_type" className="col-sm-12 col-form-label">Select Test Type</label>
                                    <div className="col-sm-12">
                                        {this.state.covidTests.map((test, idx) => (
                                            <React.Fragment key={idx}>
                                                <input type="radio" id={"test-" + idx}  name="testType" value={test.id} className="form-control" onChange={this.formHandleChange} />
                                                <label htmlFor={"test-" + idx} className="mr-4 mt-4">{test.name + " - JMD $" + test.price}</label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>

                                <div className="form-group row pull-right">
                                    <button type="button" className="btn btn-custom mr-4 btn-gray" onClick={this.resetForm} >Clear</button>
                                    <button type="submit" className="btn btn-custom " disabled={this.state.disabled}>Submit</button>
                                </div>
                            </form>
                        </div>
                        }
                    </div>
                </div >
            </section >);
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated
});

const mapActionsToProps = ({
    registration: authAction.registration,
})

export default connect(mapStateToProps, mapActionsToProps)(RegistrationPage);