import React from "react";
import { isEmpty } from "lodash";

export const ShowIcd = ({ icd, idx }) => {
    return (
        icd && <div className="content-wrapper">
            <ul className="details-listing full-width" key={idx}>
                {!isEmpty(icd.icdCrosswalks) ? icd.icdCrosswalks.map((crosswalk) => (
                    <React.Fragment key={icd.selectedIcd.icd_code - crosswalk.cpt_code}>
                        {!isEmpty(icd.selectedIcd.icd_code) && <li>
                            <span className="title">ICD Code: </span><span>{icd.selectedIcd.icd_code}</span>
                        </li>}
                        {!isEmpty(icd.selectedIcd.full_description) && <li>
                            <span className="title">ICD Description:</span>
                            <span>{icd.selectedIcd.full_description}</span>
                        </li>}
                        {crosswalk.cpt_code && <li>
                            <span className="title">CPT Code:</span>
                            <span>{crosswalk.cpt_code}</span>
                        </li>}
                        {crosswalk.full_description && <li>
                            <span className="title">CPT Description:</span>
                            <span>{crosswalk.full_description}</span>
                        </li>}
                    </React.Fragment>
                ))
                    :
                    icd && <>
                        <li>
                            <span className="title">ICD Code: </span>
                            <span>{icd.selectedIcd.icd_code}</span>
                        </li>
                        <li>
                            <span className="title">ICD Description:</span>
                            <span>{icd.selectedIcd.full_description}</span>
                        </li>
                        {/* <div>ICD Code: <span>{icd.selectedIcd.icd_code}</span></div>
                        <div>ICD Description: <span>{icd.selectedIcd.full_description}</span></div> */}
                    </>}
            </ul>
        </div>)
}