import React from 'react';
import Appointment from './Appointment';
import AuthService from '../../services/authService';
import { connect } from 'react-redux';
import * as loaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import { getDateFormat } from '../../utils/helpers';
import moment from 'moment';

class ScheduledAppointment extends React.Component {
    handleDeleteAppointment = (appointment) => {
        this.props.loaderShow();
        AuthService.deleteAppointment(appointment.patient_id, appointment.id).then(_resp => {
            this.props.loaderHide();
            Notification.show('success', { 'message': 'Appointment has been deleted.' })
            this.props.getAppointments();
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', { 'message': 'Something went wrong!' })
        })
    }
    getLabel = (date) => {
        if (moment().isSame(date, 'day')) {
            return `Today's Appointment`
        } else if (moment().add(1, 'days').isSame(date, 'day')) {
            return `Tomorrow's Appointment`
        } else {
            return `Upcoming Appointment`
        }
    }
    _onReschedule = (appointment) => {
        this.props.history.push({
            pathname: '/appointments/reschedule',
            state: {
                appointment: appointment
            }
        })
    }
    render() {
        const { appointments } = this.props;
        return <div className="scheduled_aptm">
            {appointments.data.length > 0 ? appointments.data.map(appointment => (
                <React.Fragment>
                    <p className="aptm_title_main"><span className="aptm_title">{this.getLabel(appointment.date)}</span><span className="aptm_title_date"><span>Date:</span> {getDateFormat(appointment.date)}</span></p>
                    <Appointment
                        appointment={appointment}
                        key={appointment.id}
                        type="schedule"
                        handleDeleteAppointment={() => this.handleDeleteAppointment(appointment)}
                        handleReschedule={() => this._onReschedule(appointment)}
                    />
                </React.Fragment>
            )) : <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No Record Found</th>
                        </tr>

                    </thead>
                </table>}
        </div>
    }
}
const mapActipnsToProps = {
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
}
export default connect(null, mapActipnsToProps)(ScheduledAppointment)