import * as types from './actionTypes';
import AuthService from '../services/authService';
import Notification from '../utils/notification';

export function patientAppointments({ filter }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_APPOINTMENT_LIST_REQUEST, payload: { filter } });

        AuthService.patientAppointments({ filter })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_APPOINTMENT_LIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_APPOINTMENT_LIST_FAILED, error });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}