import React from "react";
import { capitalize } from "lodash";

class ViewFamilyHistory extends React.Component {
  render() {
    let { family_history } = this.props;
    return (
      <div className="content-wrapper">
        <h6>Family History</h6>
        <ul className="details-listing">
          <li>
            Are you Adopted?{" "}
            <span>
              {family_history.is_adopted === true
                ? "Yes"
                : family_history.is_adopted === false
                ? "No"
                : "--"}
            </span>
          </li>
        </ul>
        <div className="content-wrapper">
          <ul className="details-listing">
            <li>
              Father:{" "}
              <span>
                {family_history.father_status
                  ? capitalize(family_history.father_status)
                  : "--"}
              </span>
            </li>
            <li>
              Age:{" "}
              <span>
                {family_history.father_age ? family_history.father_age : "--"}
              </span>
            </li>
            {family_history.father_death_reason && (
              <li>
                Unknown cause of death:{" "}
                <span>{family_history.father_death_reason}</span>
              </li>
            )}
          </ul>
          <ul className="details-listing">
            <li>
              Mother:{" "}
              <span>
                {family_history.mother_status
                  ? capitalize(family_history.mother_status)
                  : "--"}
              </span>
            </li>
            <li>
              Age:{" "}
              <span>
                {family_history.mother_age ? family_history.mother_age : "--"}
              </span>
            </li>
            {family_history.mother_death_reason && (
              <li>
                Unknown cause of death:{" "}
                <span>{family_history.mother_death_reason}</span>
              </li>
            )}
          </ul>
        </div>
        <div className="content-wrapper">
          {family_history.relation_conditions && (
            <React.Fragment>
              <h6>Family Member Illness/Conditions Details</h6>
              <ul className="family-member-list">
                {family_history.relation_conditions.map((item, index) => (
                  <React.Fragment key={index}>
                    <li>
                      <span>{item.illness_type}:</span>
                      <span>
                        {item.family_relation &&
                          item.family_relation.map((relation, idx) => (
                            <span key={idx}>{relation} &nbsp;</span>
                          ))}
                      </span>
                      {item.remark && (
                        <div className="prescription-filed-details">
                          <label className="main-label">Remark: </label>
                          <span> {item.remark}</span>
                        </div>
                      )}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
export default ViewFamilyHistory;
