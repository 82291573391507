import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import _ from 'lodash';
import Loader from '../../components/Loader';
import { sessionService } from 'redux-react-session';

class EditProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           name:'',
           email: '',
           contact_number: '',
           edit_name: true,
           edit_email: true,
        };

        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editName = this.editName.bind(this);
        this.editEmail = this.editEmail.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    editName() {
       this.setState({ edit_name: !this.state.edit_name });
    }
    editEmail() {
       this.setState({ edit_email: !this.state.edit_email });
    }
    handleSubmit(e) {
        e.preventDefault();
        
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.editProfile(this.state.name, this.state.email, this.props.history);
    }

    componentDidMount(){
        sessionService.loadUser().then((user) =>{ 
            this.setState({
                name: user.full_name,
                email: user.email,
                contact_number: user.contact_number
            })
        }).catch((err) =>{ 
            console.log(err)
        })
    }

    render() {
        const {name, email, contact_number} = this.state;
        return (
            <div className="container">
                <div className="middle-wrapper col-50 mx-auto">
                <div className="text-center mb-20"> 
                    <h4>Edit Profile</h4>
                    </div>
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-24">
                                <Loader/>
                                <form onSubmit={this.handleSubmit} class="form edit-profile">
                                    <div className="form-field">
                                        <input type="text" className="form-control" placeholder="Name" value={name} id="name" name="name" onChange={this.handleChange} readOnly={this.state.edit_name ? true : false} disabled= {this.state.edit_name ? true : false} />
                                        {this.validator.message('name', name, 'required')}
                                        <i class="edit-icon" onClick={this.editName}></i>
                                    </div>
                                    < div className = "form-field" >
                                        <input type="text" className="form-control" placeholder="Email" value={email} id="email" name="email" value={email} onChange={this.handleChange} readOnly={this.state.edit_email ? true : false} disabled= {this.state.edit_email ? true : false} />
                                        <i class="edit-icon" onClick={this.editEmail}></i>
                                           
                                    </div>
                                    <div className="form-field">
                                        <input type="number" className="form-control" placeholder="Mobile" value={contact_number} id="contact_number" name="contact_number" disabled />
                                    </div>
                                    
                                    <div className="form-group clearfix">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert,
    authenticated: state.session.authenticated,
    user: state.session.user,
    edit: state.editProfile
});

const mapActionsToProps = ({
    editProfile: AuthAction.editProfile
})

export default connect(mapStateToProps, mapActionsToProps)(EditProfilePage);