import React from 'react';
import { getDateFormat } from '../../../../utils/helpers'

export const Abuse = (abuseInfo) => {
    return (<div className="content-wrapper">
        <h6>Abuse</h6>
        <ul className="details-listing">
            <li>Have you ever been sexually, physically and emotionally abused? <span> {abuseInfo.is_abuse ? 'Yes' : (abuseInfo.is_abuse === false ? 'No' : '--')}</span></li>
        </ul>
        <h6>Details</h6>
        <p>{abuseInfo.abuse_reason ? abuseInfo.abuse_reason : '--'}</p>

    </div>)
}
export const SubstanceAbuse = ({ substanceAbuse }) => {
    return (
        <div className="content-wrapper">
            <h6>Substance Abuse</h6>
            <ul className="details-listing">
                <li>Substance: <span>{substanceAbuse.substance_type}</span></li>
                <li>Currently: <span>{substanceAbuse.is_currently_use === true ? 'Yes' : (substanceAbuse.is_currently_use === false ? 'No' : '--')}</span></li>
                <li>Previously Used: <span>{substanceAbuse.is_previously_used === true ? 'Yes' : (substanceAbuse.is_previously_used === false ? 'No' : '--')}</span></li>
                <li>Type/Amount/Frequency: <span>{substanceAbuse.type_quantity}</span></li>
            </ul>
            <ul className="details-listing">
                <li>How long (In Years)? <span>{substanceAbuse.how_long_years}</span></li>
                <li>If stopped then when ? <span>{substanceAbuse.stopped_when_reason}</span></li>
            </ul>
        </div>
    )
}
export const Vaccine = (vaccineInfo) => {
    return (
        <div className="content-wrapper margin-none">
            <h6>Vaccines</h6>
            <ul className="details-listing">
                <li>Tetanus: <span>{getDateFormat(vaccineInfo.tetanus_vaccacines)}</span></li>
                <li>Influenza: <span>{getDateFormat(vaccineInfo.influenza_vaccines)}</span></li>
                <li>HPV: <span>{getDateFormat(vaccineInfo.hiv_vaccines)}</span></li>
            </ul>
        </div>
    )
}
export const Excercise = (excerciseInfo) => {
    return (
        <div className="content-wrapper padding-bottom-10">
            <h6>Exercise and Other</h6>
            <ul className="details-listing">
                <li>Do you exercise? <span>{excerciseInfo.is_exercise === true ? 'Yes' : (excerciseInfo.is_exercise === false ? 'No' : '--')}</span></li>
                <li>Type and frequency of exercise: <span>{excerciseInfo.exercise_frequency ? excerciseInfo.exercise_frequency : '--'}</span></li>
                <li>Do you snore? <span>{excerciseInfo.is_sleep_snore === true ? 'Yes' : (excerciseInfo.is_sleep_snore === false ? 'No' : '--')}</span></li>
            </ul>
        </div>
    )
}
export const Sexual = ({ personal_history }) => {
    let { sexual_partners, use_condom, is_sexually_active, is_hiv_tested, hiv_test_result, hiv_test_comment, last_hiv_test } = personal_history;
    return (
        <div className="content-wrapper padding-bottom-10">
            <h6>Sexual</h6>
            <ul className="details-listing">
                <li>No. of partners: <span>{sexual_partners || '--'}</span></li>
                <li>Sexually Active: <span>{is_sexually_active === true ? 'Yes' : (is_sexually_active === false ? 'No' : '--')}</span></li>
                <li>Use of Condom: <span>{use_condom || '--'}</span></li>
            </ul>
            <ul className="details-listing">
                <li>HIV Test: <span>{is_hiv_tested === true ? 'Yes' : (is_hiv_tested === false ? 'No' : '--')}</span>
                </li>
                <li>Last HIV Test Date: <span>{last_hiv_test || '--'}</span>
                </li>
                <li>HIV Test Result: <span>{hiv_test_result || '--'}</span>
                </li>
                <li>HIV Test Result Comment: <span>{hiv_test_comment || '--'}</span>
                </li>
            </ul>
        </div>
    )
}