export const allowUserRole = [
];

export const parish = {
    'St. Andrew': 'St. Andrew',
    'Kingston': 'Kingston',
    'St. Thomas': 'St. Thomas',
    'Portland': 'Portland',
    'St. Mary': 'St. Mary',
    'St. Ann': 'St. Ann',
    'Trelawny': 'Trelawny',
    'St. James': 'St. James',
    'Hanover': 'Hanover',
    'Westmoreland': 'Westmoreland',
    'St. Elizabeth': 'St. Elizabeth',
    'Manchester': 'Manchester',
    'Clarendon': 'Clarendon',
    'St. Catherine': 'St. Catherine',
};

export const DIMENSION = 'small';

export const INSURANCE = {
    "private-insurance-sagicore": 'Private Insurance Sagicor',
    "private-insurance-medicus": 'Private Insurance Medicus',
    "private-insurance-canopy": 'Private Insurance Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other',
    'no': '--'
}

export const SPECIALIZATION = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist', 'Rheumatologist', 'Other'];

export const name_title = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Mast.'];

export const BLOODGROUP = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
