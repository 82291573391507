import React from 'react';

const ShowRadiologyTest = ({ radiology_tests }) => {
    return (<div className="content-wrapper">
        <h6>Prescribed Radiology Test</h6>
        <div>
            {radiology_tests.map((radiology_test, index) => (
                <ul className="details-listing full-width" key={index}>
                    <li>
                        <span className="title">Name: </span><span>{radiology_test.test_name} - {radiology_test.test_category ? radiology_test.test_category : '--'}</span>
                    </li>
                    <li>
                        <span className="title">Description:</span>
                        <span>{radiology_test.test_description}</span>
                    </li>
                    <li>
                        <span className="title">Remark:</span>
                        <span>{radiology_test.remark}</span>
                    </li>
                </ul>))}
        </div>
    </div>)
}
export default ShowRadiologyTest