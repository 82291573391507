export function loaderShow() {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
    }
}

export function loaderHide(message) {
    return dispatch => {
        dispatch({ type: 'HIDE_PAGE_LOADER' });
    }
}