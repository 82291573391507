import React from 'react';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { groupBy, isEmpty } from 'lodash'
import AuthService from '../../services/authService'
import { getDateFormat } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import * as authAction from '../../actions/authAction';
import SelectPage from '../../components/SelectPage';

class PatientImmunization extends React.Component {
    constructor(props) {
        super(props);
        let { patientId } = this.props.match.params
        this.state = {
            patientId: patientId,
            items: [],
            age_slugs: ''
        }
    }

    componentDidMount() {
        if (this.props.patient.patientId !== this.state.patientId) {
            this.props.getPatientById(this.state.patientId);
        }

        this.props.showLoader();
        AuthService.getPatientsImmunization(this.state.patientId).then(resp => {
            let items = groupBy(resp.data.data, 'age_slug');
            let age_slugs = Object.keys(items);
            this.setState({ items, age_slugs });
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    renderVaccineBlock = (age_slug, index) => {
        return (
            <>
                {this.state.items[age_slug].map((vaccine, idx) => (
                    this.renderVaccineItem(vaccine, idx, this.state.items[age_slug].length)
                ))}
            </>
        )
    }

    renderVaccineItem = (selected_vaccine, index, length) => {
        return (
            <tr key={index}>
                {index === 0 && <td rowSpan={length}>
                    <span className="">{selected_vaccine.age_label}</span>
                </td>}
                {/* vaccine name */}
                <td className="">
                    <span className="">{selected_vaccine.vaccine_label}</span>
                    <div><small>{selected_vaccine.note ? (selected_vaccine.note) : ''}</small></div>
                </td>
                {/* immunizatin date */}
                <td className="">
                    <span className="">{getDateFormat(selected_vaccine.immunization_date)}</span>
                </td>
                {/* manufacturer_name */}
                <td className="">
                    <span className="">{selected_vaccine.manufacturer_name}</span>
                </td>
                {/* batch_number */}
                <td className="">
                    <span className="">{selected_vaccine.batch_number}</span>
                </td>
                {/* approved_type */}
                <td className="">
                    <span className="">{this.performedByLabelFormatter(selected_vaccine.approved_type)}</span>
                </td>
                {/* approved_name */}
                <td className="">
                    <span className="">{selected_vaccine.approved_name}</span>
                </td>
                {/* approved_comment */}
                <td className="">
                    <span className="">{selected_vaccine.approved_comment}</span>
                </td>
                {/* next_immunization_date */}
                <td className="">
                    <span className="">{getDateFormat(selected_vaccine.next_immunization_date)}</span>
                </td>
            </tr>
        )
    }

    performedByLabelFormatter = (label) => {
        return label === "self" ? "Self" : "Other Doctor"
    }

    render() {
        let patient_detail = this.props.patient.successResponse;
        return (<>
            <Helmet>
                <title>Patient Immunization</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header">
                        <Link to={"/patients/" + this.state.patientId + "/prescriptions"} className="white-bg"><span>&#8249;</span> Back</Link>
                        <span className="patient-name">{patient_detail.full_name}</span>
                        <SelectPage history={this.props.history} patientId={this.state.patientId} />
                    </div>
                    <section>
                        <div className="container prescription-tabs">
                            <ul className="tabs">
                                <li className="tab-link active" data-tab="tab-1">Immunization</li>
                            </ul>
                            <div id="tab-1" className="tab-content active">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Age</th>
                                                <th>Type of Vaccine</th>
                                                <th>Date of Immunization</th>
                                                <th>Manufacturer</th>
                                                <th>Batch No./Lot No.</th>
                                                <th>Performed By</th>
                                                <th>Doctor Name</th>
                                                <th>Comment</th>
                                                <th>Next date of Immunization</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {!isEmpty(this.state.items) && this.state.age_slugs && this.state.age_slugs.map((age_slug, index) => (
                                            this.renderVaccineBlock(age_slug, index)
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>)
    }
}
const mapStateToProps = (state) => ({
    patient: state.patient
});
const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getPatientById: authAction.getPatientById
};
export default connect(mapStateToProps, mapActionsToProps)(PatientImmunization)