import React from 'react';
import moment from 'moment';
import { fullAddressStr } from '../../utils/helpers';

export const Appointment = ({ appointment, type, handleDeleteAppointment = null, handleReschedule = null }) => {
    return <ul className="aptm_list">
        <li>
            <div className="aptm_list_container">
                <div className="aptm_profile_pic">
                    <span><img src={appointment.profile_picture || "../../images/teamwork.png"} /></span>
                </div>
                <div className="aptm_detail">
                    <h3>{appointment.patient_name}</h3>
                    <ul>
                        <li>Doctor Name : <span>{appointment.doctor_name}</span></li>
                        <li>Specialization : <span>{appointment.specialization}</span></li>
                        <li>Address : <span>{fullAddressStr(appointment.address)}</span></li>
                        <li>Time : <span>{getTime(appointment.start_time)} To {getTime(appointment.end_time)}</span></li>
                    </ul>
                </div>
                {type === "schedule" && <Buttons handleDeleteAppointment={handleDeleteAppointment}
                    handleReschedule={handleReschedule} />}
            </div>
        </li>
    </ul>
}
const Buttons = ({ handleDeleteAppointment, handleReschedule }) => {
    return < div className="aptm_btn">
        <button className="btn no-bg text-uppercase w-auto min-w-inherit font-13 btn-outline-primary mr-3 pt-1 pb-1 pl-4 pr-4" onClick={() => { if (window.confirm('Delete the appointment?')) { handleDeleteAppointment() }; }}>Delete Appointment</button>
        <button className="btn text-uppercase w-auto min-w-inherit font-13 btn-primary pt-1 pb-1 pl-4 pr-4" onClick={handleReschedule}>Reschedule Appointment</button>
    </div>
}
function getTime(time) {
    return moment(time, 'HH:mm:ss').format('hh:mm A');
}
export default Appointment